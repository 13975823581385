import React from 'react';
import {Pile} from './Pile';
import { GlobalLudo } from './assets/initialState';
import { startPoint } from './assets/plot';

const Pocket = ({color, player, data, ludoAutoPlay}) => {

const { updatePile, unfreezeDice, ludostate } = GlobalLudo();

const handlePress = async(val) => {
let playerNo = val.id[0];
switch(playerNo){
case 'A':
playerNo = 'player1'
break;
case 'B':
playerNo = 'player2'
break;
case 'C':
playerNo = 'player3'
break;
case 'D':
playerNo = 'player4'
break;

default: return null;
}

const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
var parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
const currUsrOpt = parseStore.userOptions.find((e) => e.option === parseStore.activeColorOption);

let autoPile = -1;
if(currUsrOpt){
if(currUsrOpt.player2No === parseStore.chancePlayer){
autoPile = currUsrOpt.player2No;
}
}
else{
autoPile = parseStore.chancePlayer !== 1 ? parseStore.chancePlayer : -1;
}

updatePile({
playerNo : playerNo,
pileId : val.id,
pos : startPoint[parseInt(playerNo.match(/\d+/)[0], 10) - 1],
travel : 1,
noPlayer: autoPile
});

unfreezeDice({cellFreeze: -1});
}

return (
<>
<div className='ludoPocket' style={{backgroundColor: color}}>
<div className='ludoPocketMid'>
<div className='pileRow'>
<Pile 
color={color} 
player={player}
peaceNo={0} 
data={data}
onClick={handlePress}
autoPlay={ludoAutoPlay}
/>
<Pile
color={color}
player={player}
peaceNo={1}
data={data}
onClick={handlePress}
autoPlay={ludoAutoPlay}
/>
</div>
<div className='pileRow'>
<Pile 
color={color} 
player={player} 
peaceNo={2} 
data={data}
onClick={handlePress} 
autoPlay={ludoAutoPlay}
/>
<Pile 
color={color} 
player={player} 
peaceNo={3} 
data={data}
onClick={handlePress} 
autoPlay={ludoAutoPlay}
/>
</div>
</div>
</div>
</>
)
}

export default Pocket;
