import React from 'react';

const FireWorks = () => {
return (
<>
<div className='fireWorksDivWrap'>
<div className='fireWorksDiv' style={{position: 'relative'}}>
<div className='fireWorks fireWorks1'></div>
<div className='fireWorks fireWorks1'></div>
<div className='fireWorks fireWorks1'></div>
<div className='fireWorks fireWorks1'></div>
<div className='fireWorks fireWorks1'></div>
<div className='fireWorks fireWorks1'></div>
</div>

<div className='fireWorksDiv' style={{position: 'relative', left: '35px'}}>
<div className='fireWorks fireWorks2'></div>
<div className='fireWorks fireWorks2'></div>
<div className='fireWorks fireWorks2'></div>
<div className='fireWorks fireWorks2'></div>
<div className='fireWorks fireWorks2'></div>
<div className='fireWorks fireWorks2'></div>
</div>

<div className='fireWorksDiv' style={{position: 'relative', bottom: '-25px'}}>
<div className='fireWorks fireWorks3'></div>
<div className='fireWorks fireWorks3'></div>
<div className='fireWorks fireWorks3'></div>
<div className='fireWorks fireWorks3'></div>
<div className='fireWorks fireWorks3'></div>
<div className='fireWorks fireWorks3'></div>
</div>

<div className='fireWorksDiv' style={{position: 'relative', bottom: '-25px', left: '35px'}}>
<div className='fireWorks fireWorks4'></div>
<div className='fireWorks fireWorks4'></div>
<div className='fireWorks fireWorks4'></div>
<div className='fireWorks fireWorks4'></div>
<div className='fireWorks fireWorks4'></div>
<div className='fireWorks fireWorks4'></div>
</div>
</div>
</>
)
}

export default FireWorks;
