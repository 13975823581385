import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';
import AcceptAge from '../modals/AcceptAge';

const ChangePass = () => {

const { customer } = GlobalContext();

const updatePass = async() => {
if (customer.length > 0){
var lowerCaseLetter = /[a-z]/g;
var upperCaseLetter = /[A-Z]/g;
var numberLetter = /[0-9]/g;
var oldPass = $('#old_pass').val().trim();
var newPass = $('#new_pass').val().trim();
var confPass = $('#conf_pass').val().trim();

if(oldPass === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('old password required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(newPass === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('new password required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(confPass === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('confirm password required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(oldPass !== customer[0].password){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('old password no match');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(newPass !== confPass){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('confirm password no match');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(!confPass.match(upperCaseLetter)){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('minimum one capital (uppercase) letter required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(!confPass.match(lowerCaseLetter)){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('minimum one lowercase letter required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(!confPass.match(numberLetter)){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('minimum one digit (number) required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(confPass.length <= 5){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('minimum six digit password required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
$('#update-password').addClass('medGrey_button').removeClass('medGreen_button').attr('disabled', 'disabled');   
try {
const sendData = await axios.post('/server/update-password', {password: confPass});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('change password successfully');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#update-password').addClass('medGreen_button').removeClass('medGrey_button').removeAttr('disabled');
window.location.reload();
},1000); 
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#update-password').addClass('medGreen_button').removeClass('medGrey_button').removeAttr('disabled');
},1000);    
}
} catch (error) {
console.log('frontend update-password-api error: ' + error); 
}
}
}
else{
$('#AcceptAgeModal').fadeIn();   
}
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/password.png' alt='highImg' /> <span>change password</span></div>

<div className='change-password'>
<div className='input-div-md'>
<label>old password *</label>
<input type='password' id='old_pass' placeholder='enter old password' />
</div>
<div className='input-div-md'>
<label>new password *</label>
<input type='password' id='new_pass' placeholder='enter new password' />
</div>
<div className='input-div-md'>
<label>confirm password *</label>
<input type='password' id='conf_pass' placeholder='enter confirm password' />
</div>
<div className='submitBtns_RightDiv'>
<button id='update-password' className='medGreen_button' onClick={() => updatePass()}>change password</button>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default ChangePass;
