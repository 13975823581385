import React, { useState, useEffect } from 'react';
import { GlobalContext } from '../context/AppContext';
import AcceptAge from '../modals/AcceptAge';
import Footer from '../extra/Footer';
import FrontPay from './FrontPay';
import CasinoCont from './CasinoCont';

const CasinoMain = () => {

const { thGameImg } = GlobalContext();
const [pageCasImg, setPageCasImg] = useState([]);

useEffect(() => {
if(thGameImg.length > 0){
setPageCasImg(thGameImg.filter((e) => e.sport === 'casino'));
}
},[thGameImg]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='singleImage'>
{
pageCasImg.length > 0 ?
<img src={pageCasImg[0].path} alt='singleImg' />
:
<img src='/images/slide4.png' alt='singleImg' />
}
</div>

<div className='game-contents padding10'>
<FrontPay />
<div className='homeSportDiv'>
<div className='multimarketdIV'><img src='/images/casino.png' alt='highImg' /> <span>all available casino</span></div>
<CasinoCont />
</div>
<Footer />
</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge /> 
</>
)
}

export default CasinoMain;
