import React, { useEffect, useState } from 'react';
import { GlobalLudo } from './assets/initialState';

const LudoSound = () => {

const { ludostate, ludoSoundUpdate } = GlobalLudo();
const storeLudoLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLudoLocal);
const parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
const [soundLudo, setSoundLudo] = useState(parseStore.ludoSound);

const handleLudoSound = () => setSoundLudo(!soundLudo);

useEffect(() => {
ludoSoundUpdate(soundLudo);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[soundLudo]);

return (
<>
<div className='ludoSoundOpt'>
<div className='ludoSoundWrap'>
<span className='smTxtSo'>sound</span>
<label className="ludoSoundswitch">
<input type="checkbox" className='ludoSoundcheckbox' checked={soundLudo} onChange={handleLudoSound} />
<span className="ludoSoundslider"></span>
</label>
</div>
<div className='ludoActiveUsr'>
<i className='fa fa-user'></i>
<span>{parseStore.activeLudoUser}</span>
</div>
</div>
</>
)
}

export default LudoSound;
