import React, { useMemo } from 'react';
import FireWorks from '../FireWorks';
import { GlobalLudo } from './assets/initialState';
import { GlobalContext } from '../../context/AppContext';

const customerDataApi = '/server/active-customer';
const userExposure = '/server/user-exposure';

const LudoBack = ({win}) => {

const { GetUserList, GetUserExposure } = GlobalContext();
const { ludostate, resetLudoState, ludoSoundUpdate } = GlobalLudo();
const storeLudoLocal = localStorage.getItem('localLudoState');
const storeLocal = JSON.parse(storeLudoLocal);
const parseLudo = storeLocal !== null && storeLocal.find((e) => e.activeLudoUser === ludostate.activeLudoUser);

const backLudo = () => {
GetUserList(`${customerDataApi}`);
GetUserExposure(`${userExposure}`); 
resetLudoState();
ludoSoundUpdate(false);
}

const findWinLose = useMemo(() => {
if(parseLudo.currPlayTeamOpt === 2){
const findUsrOpt = parseLudo.userOptions.find((e) => e.option === parseLudo.activeColorOption);

if(findUsrOpt){
if(findUsrOpt.player1No === parseLudo.winner){
return true;
}
else{
return false;
}
}
}
else{
if(parseLudo.winner === 1){
return true;
}
else{
return false;
}
}
},[parseLudo]);

return (
<>
<div className='ludoBackDivWrap'>
<div className='ludoBackDiv'>
<FireWorks />
<div className='ludoBackDivCon'>
<div className='ludoWinnerDiv'>
<span>winner</span>
<span>player no {win}</span>
<span style={{color: findWinLose ? '#8BC34A' : '#E91E63'}}>you {findWinLose ? 'win' : 'lose'}</span>
<button onClick={backLudo}>back to home</button>
</div>
</div>
</div>
</div>
</>
)
}

export default LudoBack;
