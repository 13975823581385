const ludoReduce = (ludostate, action) => {
switch (action.type) {
case 'dice_run':
return {
...ludostate,
diceNo: action.payload,
isDiceRoll: true
}  

case 'enable_pile_player':
const { player, noPlayerPiles } = action.payload;
return {
...ludostate,
touchDiceBlock: true,
selectionPilePlayer: player,
autoPlayPile: noPlayerPiles
}  

case 'enable_cell_player':
const { playerCells, autoCells } = action.payload;
return {
...ludostate,
touchDiceBlock: true,
selectionCellPlayer: playerCells,
autoPlayCell: autoCells
}  

case 'disable_touch':
return {
...ludostate,
touchDiceBlock: true,
selectionPilePlayer: -1,
selectionCellPlayer: -1
}  

case 'unfreeze_dice':
const { cellFreeze } = action.payload;
return {
...ludostate,
touchDiceBlock: false,
isDiceRoll: false,
autoPlayCell: cellFreeze
}  

case 'winner_ludo':
return {
...ludostate,
winner: action.payload
}  

case 'chance_player':
const {plNo, playAto, noPlayers, autoCell} = action.payload;
//console.log(plNo +' -- '+ playAto);
return {
...ludostate,
chancePlayer: plNo,
autoPlay: playAto,
isDiceRoll: false,
autoPlayPile: noPlayers,
autoPlayCell: autoCell
} 

case 'aanounce_winner':
return {
...ludostate,
winner: action.payload
} 

case 'update_pile':
const {playerNo, pileId, pos, travel, noPlayer } = action.payload;
const playerPile = ludostate[playerNo];
const currPile = playerPile.find((p) => p.id === pileId);
ludostate.selectionPilePlayer = -1;
ludostate.selectionCellPlayer = -1;
ludostate.autoPlayPile = noPlayer;

if(currPile){
currPile.pos = pos;
currPile.travel = travel;
const currPosIndex = ludostate.currentPosition.findIndex((p) => p.id === pileId);
if(pos === 0){
if(currPosIndex !== -1){
ludostate.currentPosition.splice(currPosIndex, 1);
}
}
else{
if(currPosIndex !== -1){
ludostate.currentPosition[currPosIndex] = {
id: pileId,
pos
}
}
else{
ludostate.currentPosition.push({id: pileId, pos}); 
}
}
}

return{
...ludostate
}

case 'update_currPlay_teamOpt':
return{
...ludostate,
currPlayTeamOpt: action.payload
}

case 'update_color_option':
return{
...ludostate,
activeColorOption: action.payload
}

case 'update_pile_option':
return{
...ludostate,
activePileOption: action.payload
}

case 'update_LudoBet_amount':
return{
...ludostate,
activeBetAmount: action.payload
}

case 'genrate_ludoMid':
let mid = 15+'.'+ Math.floor((Math.random() * 100000 * Date.now()).toString().slice(0, 10));
return{
...ludostate,
ludoMid: mid
}

case 'active_ludo_bet':
return{
...ludostate,
ludoBetStatus: action.payload,
}

case 'reset_ludo_state':
return action.payload;

case 'ludo_sound_update':
return{
...ludostate,
ludoSound: action.payload,
}

case 'ludo_user_update':
return{
...ludostate,
activeLudoUser: action.payload,
}

case 'auto_play_ludo':
return{
...ludostate,
autoPlay: action.payload,
}

case 'update_autoCell':
return{
...ludostate,
autoPlayCell: action.payload,
}
   
default: return ludostate;
}
}

export default ludoReduce;