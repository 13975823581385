import React, { useMemo, useState } from 'react';
import { backGgroundImage } from './assets/images';
import { GlobalLudo } from './assets/initialState';
import {playLudoSound} from './assets/sound';

const Dice = ({color, player, disePos, data, ludoAutoPlay}) => {

const { 
updateDice,
updateChancePlayer,
enablePilePlayer,
enableCellPlayer,
ludostate
} = GlobalLudo();

const [diceRolling, setDiceRolling] = useState(false);
const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
const parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
//console.log(parseStore)
const diceNo = parseStore.diceNo;
const chance_Player = parseStore.chancePlayer;
const playerPease = chance_Player === 1 ? parseStore.player1 : 
chance_Player === 2 ? parseStore.player2 : 
chance_Player === 3 ? parseStore.player3 : parseStore.player4;
const isDiceRoll = parseStore.isDiceRoll;
const pilePlayer = parseStore.autoPlayPile;
const cellPlayer = parseStore.autoPlayCell;
const teamWinner = parseStore.winner;

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const handleDicePress = async() => {
const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
var parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
let newDiceNo = Math.floor(Math.random() * 6) + 1;
//console.log(ludostate)
//let newDiceNo = 2;
var auto_PlayLudo = false;
parseStore.ludoSound && playLudoSound('dice_roll');
setDiceRolling(true);
await delay(800);
updateDice(newDiceNo);
setDiceRolling(false);

//console.log(parseStore.activePileOption);
//console.log(data);
//console.log(data.slice(0, parseStore.activePileOption));
const isAnyPeaseAlive = data.slice(0, parseStore.activePileOption).findIndex(i => i.pos !== 0 && i.pos !== 57);
const isAnyPeaseLock = data.slice(0, parseStore.activePileOption).findIndex(i => i.pos === 0);
const currUsrOpt = parseStore.userOptions.find((e) => e.option === parseStore.activeColorOption);
const numberActPile = data.slice(0, parseStore.activePileOption).filter((e) => e.pos > 0);
//console.log(numberActPile)

if(isAnyPeaseAlive === -1){
if(newDiceNo === 6){
enablePilePlayer({player: player, noPlayerPiles: -1});
}
else{
let chancePlayer = player;

if(currUsrOpt){
if(currUsrOpt.player1No === chance_Player){
chancePlayer = currUsrOpt.player2No;
auto_PlayLudo = true;
}
else{
chancePlayer = currUsrOpt.player1No;
auto_PlayLudo = false;
}
}
else{
let currDicePlayer = chancePlayer + 1;
chancePlayer = currDicePlayer;
if(chancePlayer > 4){
chancePlayer = 1;
}

if(chancePlayer !== 1){
auto_PlayLudo  = true;
}
else{
auto_PlayLudo  = false;
}
}

await delay(600);
updateChancePlayer({plNo: chancePlayer, playAto: auto_PlayLudo, noPlayers: -1, autoCell: -1});
}
}else{

const canMove = playerPease.slice(0, parseStore.activePileOption).some((pile) => pile.travel + newDiceNo <= 57 && pile.pos !== 0);

if(
(!canMove && newDiceNo === 6 && isAnyPeaseLock === -1)
|| (!canMove && newDiceNo !== 6 && isAnyPeaseLock !== -1)
|| (!canMove && newDiceNo !== 6 && isAnyPeaseLock === -1)
){

let chancePlayer = player;

if(currUsrOpt){
if(currUsrOpt.player1No === chance_Player){
chancePlayer = currUsrOpt.player2No;
auto_PlayLudo  = true;
}
else{
chancePlayer = currUsrOpt.player1No;
auto_PlayLudo  = false;
}
}
else{
let currDicePlayer = chancePlayer + 1;
chancePlayer = currDicePlayer;
if(chancePlayer > 4){
chancePlayer = 1;
}

if(chancePlayer !== 1){
auto_PlayLudo  = true;
}
else{
auto_PlayLudo  = false;
}
}

await delay(600);
updateChancePlayer({plNo: chancePlayer, playAto: auto_PlayLudo, noPlayers: -1, autoCell: -1});
return;
}

if(newDiceNo === 6 && numberActPile.length < parseStore.activePileOption){
enablePilePlayer({player: player, noPlayerPiles: -1});
}

enableCellPlayer({playerCells: player, autoCells: -1});

}
}

useMemo(() => {
if(chance_Player === player && ludoAutoPlay && teamWinner === null){
setTimeout(() => {
handleDicePress();
},1000);
//console.log('run 1 dice auto');
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[chance_Player, ludoAutoPlay, player, teamWinner]);

useMemo(() => {
if(ludoAutoPlay && pilePlayer === player && teamWinner === null){
setTimeout(() => {
handleDicePress();
},1000);
//console.log('run 2 dice auto');
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[ludoAutoPlay, pilePlayer, player, teamWinner]);

useMemo(() => {
if(ludoAutoPlay && cellPlayer === player && teamWinner === null){
setTimeout(() => {
handleDicePress();
},1000);
//console.log('run 2 dice auto');
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[ludoAutoPlay, cellPlayer, player, teamWinner]);

return (
<>
{
disePos === 'left' ?
<div className='ludoDiceCol'>
<div className='ludoDicePile ludoDicePileLeft'>
<img src={backGgroundImage('pile'+player).image} alt='ludo_dice_pile' />
</div>
<div className='ludoDiceGradiant'>
<div className='ludoDice'>
{
(chance_Player === player) &&
<button className='ludoDiceimgDiv' onClick={() => handleDicePress()} disabled={isDiceRoll || ludoAutoPlay}>
{
!diceRolling ?
<div className='activeLudoDice'>
<img src={backGgroundImage('dice'+diceNo).image} alt='ludo_dice' />
</div>
:
<div className='activerollLudoDice'>
<img src={backGgroundImage('dice7').image} alt='ludo_dice' />
</div>
}
</button>
}
</div>
</div>
<div className='indicateLeftArrow'>
{
(chance_Player === player) && !diceRolling &&
<img src={backGgroundImage('arrowPoint').image} alt='indicate-arrow' />
}
</div>
</div>
:
<div className='ludoDiceCol'>
<div className='indicateRightArrow'>
{
(chance_Player === player) && !diceRolling &&
<img src={backGgroundImage('arrowPoint').image} alt='indicate-arrow' />
}
</div>
<div className='ludoDiceGradiant'>
<div className='ludoDice'>
{
(chance_Player === player) &&
<button className='ludoDiceimgDiv' onClick={() => handleDicePress()} disabled={isDiceRoll || ludoAutoPlay}>
{
!diceRolling ?
<div className='activeLudoDice'>
<img src={backGgroundImage('dice'+diceNo).image} alt='ludo_dice' />
</div>
:
<div className='activerollLudoDice'>
<img src={backGgroundImage('dice7').image} alt='ludo_dice' />
</div>
}
</button>
}
</div>
</div>
<div className='ludoDicePile ludoDicePileRight'>
<img src={backGgroundImage('pile'+player).image} alt='ludo_dice_pile' />
</div>
</div>
}
</>
)
}

export default Dice;
