import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';
import { casinoResMod_ab } from '../casino/andarBahar/js/result';
import AbMod from '../casino/modals/AbMod';
import { casinoResMod_dt1day } from '../casino/dt1day/js/result';
import ResultDt from '../casino/modals/ResultDt';
import { casinoResMod_dt20 } from '../casino/dt20/js/result';
import { casinoResMod_dt202 } from '../casino/dt202/js/result';
import { casinoResMod_lu7a } from '../casino/lucky7A/js/result';
import Lu7Mod from '../casino/modals/Lu7Mod';
import { casinoResMod_lu7b } from '../casino/lucky7B/js/result';
import { casinoResMod_t20 } from '../casino/t20/js/result';
import ResultMod from '../casino/modals/ResultMod';
import { casinoResMod_t201day } from '../casino/t20one_day/js/result';
import { casinoResMod_teenTest } from '../casino/t20test/js/result';
import ResultTs from '../casino/modals/ResultTs';

const History = () => {

const location = useLocation();
const  { GetProfitLoss, profitLossUser } = GlobalContext();
const [currEvent, setCurrEvent] = useState([]);

const hisRes = (sport, mid) => {
if(sport === 'andar_bahar'){
casinoResMod_ab('resMid_'+mid);
$('#casResModal_ab').show();
$('.casDetResLoad_ab').css({display: 'flex'});
$('.resCasCon_ab').hide();
}
else if(sport === 'dt_1day'){
casinoResMod_dt1day('resMid_'+mid);
$('#casResModaldt').show();
$('.casDetResLoaddt').css({display: 'flex'});
$('.resCasCondt').hide();
}
else if(sport === 'dt20'){
casinoResMod_dt20('resMid_'+mid);
$('#casResModaldt').show();
$('.casDetResLoaddt').css({display: 'flex'});
$('.resCasCondt').hide();
}
else if(sport === 'dt202'){
casinoResMod_dt202('resMid_'+mid);
$('#casResModaldt').show();
$('.casDetResLoaddt').css({display: 'flex'});
$('.resCasCondt').hide();
}
else if(sport === 'lucky7a'){
casinoResMod_lu7a('resMid_'+mid);
$('#casResModal_lu7a').show();
$('.casDetResLoad_lu7a').css({display: 'flex'});
$('.resCasCon_lu7a').hide();
}
else if(sport === 'lucky7b'){
casinoResMod_lu7b('resMid_'+mid);
$('#casResModal_lu7a').show();
$('.casDetResLoad_lu7a').css({display: 'flex'});
$('.resCasCon_lu7a').hide();
}
else if(sport === 't20'){
casinoResMod_t20('resMid_'+mid);
$('#casResModal').show();
$('.casDetResLoad').css({display: 'flex'});
$('.resCasCon').hide();
}
else if(sport === 't20_1day'){
casinoResMod_t201day('resMid_'+mid);
$('#casResModal').show();
$('.casDetResLoad').css({display: 'flex'});
$('.resCasCon').hide();
}
else if(sport === 'teen_test'){
casinoResMod_teenTest('resMid_'+mid);
$('#casResModalTs').show();
$('.casDetResLoadTs').css({display: 'flex'});
$('.resCasConTs').hide();
}
}

useEffect(() => {
var select_sport = location.state !== null ? location.state.sport : 'none';
var start_date = new Date(location.state !== null && location.state.startDate).toDateString();
var end_date = new Date(location.state !== null && location.state.endDate).toDateString();
GetProfitLoss(select_sport,start_date,end_date);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location.state]);

useEffect(() => {
if(profitLossUser.length > 0){
if(location.state !== null && (location.state.sec === 'fancy' || location.state.sec === 'ball' || location.state.sec === 'over' || location.state.sec === 'meter' || location.state.sec === 'khado' || location.state.sec === 'oddeven' || location.state.sec === 'fancy1' || location.state.sec === 'line')){
setCurrEvent(profitLossUser.filter((e) => e.settlement === 'settle' && e.sport === location.state.sport && e.eventName === location.state.event && e.team === location.state.team && e.eventid === location.state.eventid));
}
else{
setCurrEvent(profitLossUser.filter((e) => e.settlement === 'settle' && e.sport === location.state.sport && e.eventName === location.state.event && e.sec === location.state.sec && e.eventid === location.state.eventid));
}
}
},[profitLossUser, location.state]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'>
<div className='marketCol'>
<img src='/images/pls.png' alt='highImg' />
<span>profi loss (history)</span>
</div>
{
location.state !== null && location.state.event === 'casino' &&
<div className='marketCol'>
<button className='resMarBtn' onClick={() => hisRes(location.state.sport, location.state.eventid)}>result</button>
</div>
}
</div>

<div className='simple-div'>

<div className='tabel-responsiveDiv'>
<table className='tabel-md-res'>
<thead>
<tr>
<th>sport name</th>
<th>event name</th>
<th>market name</th>
<th>runner name</th>
<th>bet type</th>
<th>odds</th>
<th>size</th>
<th>bet amount</th>
<th>profit</th>
<th>loss</th>
<th>result</th>
<th>match date</th>
<th>place date</th>
</tr>
</thead>
<tbody>
{
currEvent.length > 0 ? currEvent.map((data, index) => {
const selfDate = data.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
//console.log(placeDate);
if(data.bettype === 'back'){
return(
<tr key={index} className='backTr'>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>{data.sec}</td>
<td>{data.team}</td>
<td>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over'
|| data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven'
|| data.sec === 'fancy1' || data.sec === 'line' ? 'yes' : data.bettype
}
</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.loss.toFixed(2)).toLocaleString()}</td>
<td>{data.settlement === 'settle' ? data.winner : 'void'}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)
}
else{
return(
<tr key={index} className='layTr'>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>{data.sec}</td>
<td>{data.team}</td>
<td>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over'
|| data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven'
|| data.sec === 'fancy1' || data.sec === 'line' ? 'no' : data.bettype
}
</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.loss.toFixed(2)).toLocaleString()}</td>
<td>{data.settlement === 'settle' ? data.winner : 'void'}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)
}
})
:
<tr>
<td colSpan='14' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>

<AbMod />
<ResultDt />
<Lu7Mod />
<ResultMod />
<ResultTs />
</>
)
}

export default History;
