import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';
import MarketBets from '../../allBets/MarketBets';
import { check_teenTest, start_teenTest } from './js/refresh';
import { runresult_teenTest, startResult_teenTest } from './js/result';
import { startSettle_teenTest, runSettle_teenTest } from './js/settle';
import ResultTs from '../modals/ResultTs';
import TeenRules from '../modals/TeenRules';
import { 
oddsClick_teenTest,
cancelBet_teenTest,
minusAmt_teenTest,
plusAmt_teenTest,
changeAmt_teenTest,
stakeBet_teenTest,
betSend_teenTest,
pls_teenTest
} from './js/betting';

const T20Test = () => {

const [load_teentest, setLoadTeenTest] = useState(true);
const [curr_teentest, setCurrTeenTest] = useState([]);
const { customer } = GlobalContext();

const openteenRules = () => {
$('#teenModal').show();
}

useEffect(() => {
async function fetchteenTest() {
try {
const getData = await axios.get('/server/t20test_Data');
const resData = await getData.data;
if(resData.data !== undefined){
setLoadTeenTest(false);
setCurrTeenTest(resData);
}
else{
setLoadTeenTest(true);
setCurrTeenTest([]);
}
} catch (error) {
console.log('frontend casino_teentest_data error : ' + error);
}
}

fetchteenTest();
},[]);

useEffect(() => {
check_teenTest();
runresult_teenTest();
startSettle_teenTest();
pls_teenTest(curr_teentest.data !== undefined ? curr_teentest.data.t1[0].mid : 0,'teen_test');
return () => {
clearInterval(start_teenTest);
clearInterval(startResult_teenTest);
clearInterval(runSettle_teenTest);
}
},[curr_teentest]);

if(load_teentest){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='game-contents'>

{
curr_teentest.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>teenpatti test</span>
<span className='casGamerule' onClick={openteenRules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_teenTest'>{curr_teentest.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>tiger</span>
<div className='teamLiveCards'>
<img id='card1_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card3_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div> 
<div className='col'>
<span className='cardTeam'>lion</span>
<div className='teamLiveCards'>
<img id='card4_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c4.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card5_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c5.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card6_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c6.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>   
<div className='col'>
<span className='cardTeam'>dragon</span>
<div className='teamLiveCards'>
<img id='card7_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c7.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card8_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c8.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card9_teenTest' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_teentest.data.t1[0].c9.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>  
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_teenTest'>{curr_teentest.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3048' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh'><span>tiger</span></th>
<th className='backTh'><span>lion</span></th>
<th className='backTh'><span>dragon</span></th>
</tr>
</thead>
{
curr_teentest.data.t2.map((data, index) => {
return (
<tbody key={index}>
<tr>
<td>
<div className='teamTdCas'>
<span>{data.nation}</span>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino2' id={'locCasino_teenTest_'+index}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'oddsCas_teenTest_'+data.tsection} onClick={() => oddsClick_teenTest(index,data.nation,data.trate,data.tsection,'tiger')}>
<span className='casRate' id={'casRate_teenTest_'+data.tsection}>{data.trate}</span>
<span className='teenTestPls' id={'pls_teenTest_'+data.tsection}>0</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_teenTest_'+data.lsection} onClick={() => oddsClick_teenTest(index,data.nation,data.lrate,data.lsection,'lion')}>
<span className='casRate' id={'casRate_teenTest_'+data.lsection}>{data.lrate}</span>
<span className='teenTestPls' id={'pls_teenTest_'+data.lsection}>0</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_teenTest_'+data.dsectionid} onClick={() => oddsClick_teenTest(index,data.nation,data.drate,data.dsectionid,'dragon')}>
<span className='casRate' id={'casRate_teenTest_'+data.dsectionid}>{data.drate}</span>
<span className='teenTestPls' id={'pls_teenTest_'+data.dsectionid}>0</span>
</button>
</td>
</tr>

<tr style={{display:'none'}} id={'placeBetCasino_teenTest_'+index}>
<td style={{padding:'0'}} colSpan='4'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrapCas_teenTest_'+index}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msgCas_teenTest_'+index}></span>
</div>
<div className='bet-input-row'>
<span style={{display:'none'}} id={'selBetType_teenTest_'+index}>back</span>
<span style={{display:'none'}} id={'sec_teenTest_'+index}>match_odds</span>
<span style={{display:'none'}} id={'sport_teenTest_'+index}>teen_test</span>
<span style={{display:'none'}} id={'teamCas_teenTest_'+index}></span>
<span style={{display:'none'}} id={'userCurr_teenTest_'+index}>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_teenTest'>{curr_teentest.data.t1[0].mid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_teenTest(index)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id={'bet_rate_teenTest_'+index} placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt_teenTest(index)}>-</span>
<input type='number' id={'bet_amount_teenTest_'+index} placeholder='0' onChange={(e) => changeAmt_teenTest(index,e.target.value)} />
<span onClick={() => plusAmt_teenTest(index)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_teenTest(index)} className='' id={'betsPlace_teenTest_'+index}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_teenTest_'+index}>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet_teenTest(index,customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_teenTest(index)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_teenTest(index)} className='' id={'betsPlace2_teenTest_'+index}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>
</tbody>
)
})
}
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_teenTest'></div>
</div>

<MarketBets eventid={curr_teentest.data !== undefined ? curr_teentest.data.t1[0].mid : 0} />
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>

<TeenRules />
<ResultTs />
</>
)
}

export default T20Test;
