import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';

const Cricket = () => {

const Navigate = useNavigate();
const { cicList, customer, spoSett, gameSett, cricCompAll } = GlobalContext();
const [lockSport, setLockSport] = useState([]);

const openMarket = (eventid, marketid) => {
Navigate('/market-4/'+eventid+'/'+marketid);
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);
    
useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lock5').css({display: 'flex'});
}else{
$('#sports_lock5').hide();
}
}
},[lockSport]);

useEffect(() => {
if(cicList.length > 0){
cricCompAll.filter((e) => gameSett.find((o) => parseFloat(e.comp_id) === parseFloat(o.eventid) && o.sport === 'cricket')).forEach((k) => {
$('#GameLock_'+k.event_id).css({display: 'flex'});
});
}
},[gameSett, cricCompAll, cicList]);

return (
<>
<div className='homeSportDiv'>
<div className='multimarketdIV'><img src='/images/cricketW.png' alt='highImg' /> <span>cricket in-play tomorrow</span></div>

<div className='sports-page'>

<div className='sports_lock' id='sports_lock5'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel sportsPageTable2'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>X</th>
<th>2</th>
</tr>
</thead>
{
cicList.length > 0 ? cicList.map((data, index) => {
var fTrue = '';
var tvTrue = '';
var inPlay = '';
var ename = data.eventname.split('/');
var splitDate = ename[1].replaceAll('(ist)', '').replaceAll('am', ' am').replaceAll('pm', ' pm');
var eventTime = '';
var timeParse = Date.parse(splitDate);
if(data.f === 'true'){
fTrue = <span className='f-avail'><img src='/images/letter-f.png' alt='sportsIco' /></span>
}
if(data.tv === 'true'){
tvTrue = <span className='tv-avail'><img src='/images/tv.png' alt='sportsIco' /></span>
}
if(data.inplay === 'true'){
inPlay = <span className='inplay-avail'><img src='/images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString('en-IN')}</span>;
}

const [day, month, year] = new Date(timeParse).toLocaleDateString('en-IN').split('/');
let parseDataDate = Date.parse(`${month}/${day}/${year}`);
const today = new Date();
const tomorrowDate = new Date();
tomorrowDate.setDate(today.getDate()+1);
const [day1, month1, year1] = tomorrowDate.toLocaleDateString('en-IN').split('/');
let parseCurrDate = Date.parse(`${month1}/${day1}/${year1}`);
if(parseDataDate === parseCurrDate){
return(
<tbody key={index}>

<tr>
<td className='GameLock' id={'GameLock_'+data.gameid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gameid, data.marketid)} className='event-name'>{ename[0].trim()}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{inPlay}{fTrue}{tvTrue}</span></td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back1}</span>
<span className='lay-sports-page'>{data.lay1}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back11}</span>
<span className='lay-sports-page'>{data.lay11}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back12}</span>
<span className='lay-sports-page'>{data.lay12}</span>
</td>
</tr>
</tbody>
)
}
else{
return null;
}
})
:
<tbody>
<tr>
<td colSpan='5'>No cricket event in inplay tomorrow...</td>
</tr>
</tbody>
}
</table>
</div>
</div>
</>
)
}

export default Cricket;
