import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/AppContext';
import { 
stakeBet,
minusAmt,
plusAmt,
changeAmt,
oddsClick,
allSidNormal,
allTeamKhado,
cancelBet,
betSend,
khadoFancy_pls
} from '../js/fancyBetting';
import { bookFancy } from '../../../fancyBook/book';
import { useParams } from 'react-router-dom';

const Khado = () => {

const { customer, cricMarket } = GlobalContext();
const [currData, setCurrData] = useState([]);
const { eventid, marketid } = useParams();

// console.log(currData);

useEffect(() => {
if(cricMarket.length !== 0){
setCurrData(cricMarket.data !== undefined && cricMarket.data.filter((e) => e.mname.trim() === "khado" && e.gtype === "khado"));
}
},[cricMarket]);

useEffect(() => {
if(currData.length > 0){
khadoFancy_pls(eventid,'cricket');
}
},[currData, eventid]);

return (
<>
{
currData.length > 0 && 
<table className='oddsTable fancyTab'>
<thead>
<tr>
<th className='minWidth' colSpan='2'>
<span className='min-max diskHide'><span className='minF'>min/max :</span> <span className='minS matchMinFancy'>0-1</span></span>
</th>
<th className='noth'>no</th>
<th className='yesth'>yes</th>
<th className='minMaxTh mobHide'>Min-Max</th>
</tr>
</thead>
{
currData[0].section.map((data, index) => {
if(!allSidNormal.includes(data.sid)){
allSidNormal.push(data.sid);
}

var RunData = {
sec : 'khado',
runner: data.nat,
mid: currData[0].mid,
sid: data.sid,
}

const findSid = allTeamKhado.findIndex(o => o.sid === data.sid);

if(findSid === -1){
allTeamKhado.push(RunData);
}

return (
<tbody key={index} style={{position: 'relative'}}>

{
currData.length > 0 &&
<tr className='blockTrF' id={'blockTrF_'+currData[0].mid+data.sid}>
<td colSpan='5'><span>suspended</span></td>   
</tr>
}

<tr>
<td>
<span className='teamWrap'>
<span className='teamName' id={'teamSel_'+currData[0].mid+data.sid}>{data.nat}</span>
<span className='teamName'> - {(data.odds[0].oname === 'lay1' ? data.odds[0].odds : data.odds[1].odds)}</span>
</span>
<span className='pls' id={'plsArr_'+currData[0].mid+data.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+currData[0].mid+data.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+currData[0].mid+data.sid}></span>
</td>
<td className='bookBtnTd'>
<button className='bookBtn' onClick={() => bookFancy(eventid,data.nat,currData[0].mid,data.sid,customer.length > 0 ? true : false)}>book</button>
</td>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay1').map((data2, index2) => {
return(
<td className='counts-td lay1Div' key={index2}>
<button className='oddsDiv'>
<span className='price'>0</span>
<span className='size'>0.0</span>
</button>

<div className='suspendSmall' id={'suspendFancy_'+currData[0].mid+data.sid}>
<span id={'suspendFancyTxt_'+currData[0].mid+data.sid}>suspended</span>
</div>
</td>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back1').map((data2, index2) => {
return(
<td className='counts-td back1Div' key={index2}>
<button className='oddsDiv' id={'oddsDiv_'+currData[0].mid+data.sid+data2.tno} onClick={() => oddsClick(currData[0].mid,data2.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+currData[0].mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+currData[0].mid+data.sid+data2.tno}>{data2.size}</span>
</button>
</td>
)
})   
}

<td className='minMaxTd mobHide'>
<span className='minMax matchMinFancy'>0-1</span>
</td>
</tr>

<tr style={{display:'none'}} id={'placeBet_'+currData[0].mid+data.sid}>
<td style={{padding:'0'}} colSpan='7'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrap_'+currData[0].mid+data.sid}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msg_'+currData[0].mid+data.sid}></span>
</div>
<div className='bet-input-row' id={'betRow_'+currData[0].mid+data.sid}>
<span style={{display:'none'}} id={'selBetType_'+currData[0].mid+data.sid}></span>
<span style={{display:'none'}} id={'bet-size_'+currData[0].mid+data.sid}></span>
<span style={{display:'none'}} id={'tno_'+currData[0].mid+data.sid}></span>
<span style={{display:'none'}} id={'sec_'+currData[0].mid+data.sid}>khado</span>
<span style={{display:'none'}} id={'sport_'+currData[0].mid+data.sid}>cricket</span>
<span style={{display:'none'}} id={'eventid_'+currData[0].mid+data.sid}>{eventid}</span>
<span style={{display:'none'}} id={'marketid_'+currData[0].mid+data.sid}>{marketid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet(currData[0].mid,data.sid)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id={'bet-rate_'+currData[0].mid+data.sid} placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt(currData[0].mid,data.sid)}>-</span>
<input type='number' id={'bet-amount_'+currData[0].mid+data.sid} placeholder='0' onChange={(e) => changeAmt(currData[0].mid,data.sid,e.target.value)} />
<span onClick={() => plusAmt(currData[0].mid,data.sid)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend(currData[0].mid,data.sid)} className='' id={'betsPlace_'+currData[0].mid+data.sid}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_'+currData[0].mid+data.sid}>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet(currData[0].mid,data.sid, customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet(currData[0].mid,data.sid)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend(currData[0].mid,data.sid)} className='' id={'betsPlace2_'+currData[0].mid+data.sid}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>

</tbody>
)
})
}
</table>
}
</>
)
}

export default Khado;
