import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';

const Statement = () => {

const { customer, couponAll } = GlobalContext();
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const [detaStatement, setDetaStatement] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = detaStatement.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(detaStatement.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);
const [yourCou, setYourCou] = useState([]);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const closeViewUse = () => {
$('#couponShDiv').hide(); 
}

const opViewUse = () => {
$('#couponShDiv').css({display: 'flex'}); 
}

useEffect(() => {
async function fetchData(){
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
//console.log(start_date +' -- '+ end_date);

try {
const sendData = await axios.post("/server/statement", {
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
if(resData.length > 0){
setDetaStatement(resData);
}
else{
setDetaStatement([]);  
}
} catch (error) {
console.log('frontend statement_data error : ' + error);
}
}

fetchData();
},[startDate, endDate]);

useEffect(() => {
var couFi = [];
if(customer.length > 0){
for(let i = 0; i < customer.length; i++){
for(let j = 0; j < couponAll.length; j++){
if(customer[i].applycode === couponAll[j].couponCode && customer[i].applyStatus === 'applied'){
var data = {
couName: couponAll[j].couponName,
couCode: couponAll[j].couponCode,
couMin: couponAll[j].minDepo,
couBon: couponAll[j].couponDis
}

const findMatch = couFi.findIndex((o) => o.couCode === couponAll[j].couponCode);
if(findMatch === -1){
couFi.push(data);  
}
}
}
}
}
setYourCou(couFi);
},[customer, couponAll]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'>
<div className='marketCol'>
<img src='/images/bank-statement.png' alt='highImg' /> <span>statement</span>
</div>
{
yourCou.length > 0 &&
<div className='marketCol'>
<span className='usedCou' onClick={opViewUse}>coupon used</span>
</div>
}
</div>

<div className='couponShDiv' id='couponShDiv'>
<div className='content_cou'>
<i className='fa fa-close' onClick={closeViewUse} />
{
yourCou.length > 0 ? 
<div className='couponDes'>
<span className='cou1'>{yourCou[0].couName}</span>
<span className='cou2'>min deposit : {yourCou[0].couMin}</span>
<div className='couCodeo'>
<span>{yourCou[0].couCode}</span>
<span>coupon bonus ₹{yourCou[0].couBon}</span>
</div>
</div>
:
<div className='expiredDi'>
<img src='/images/alertExp.png' alt='alertExp' />
<span>coupon has been expired.</span>
</div>
}
</div>
</div>

<div className='simple-div'>

<div className='searchItemsDiv'>
<div className='four-column-row'>
<div className='four-column'>
<div className='input-div-md'>
<label>start date</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
</div>
<div className='four-column'>
<div className='input-div-md'>
<label>end date</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>
</div>
</div>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {detaStatement.length} records...</span>
</div>

<div className='tabel-responsiveDiv'>
<table className='tabel-md-res'>
<thead>
<tr>
<th>date/time</th>
<th>deposit</th>
<th>withdrawl</th>
<th>balance</th>
<th>new bal.</th>
<th>remark</th>
<th>from/to</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.create_date}</td>
<td>{data.deposit !== null ? parseFloat(data.deposit.toFixed(2)).toLocaleString() : 0}</td>
<td>{data.withdrawl !== null ? parseFloat(data.withdrawl.toFixed(2)).toLocaleString() : 0}</td>
<td>{parseFloat(data.curr_bal.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.new_bal.toFixed(2)).toLocaleString()}</td>
<td>{data.remark}</td>
<td>{data.sender} <i className='fa fa-arrow-right'></i> {data.receiver}</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Statement;
