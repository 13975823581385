import React, { useEffect, useMemo } from 'react';
import LudoMain from './LudoMain';
import LudoStart from './LudoStart';
import { GlobalLudo } from './assets/initialState';
import { GlobalContext } from '../../context/AppContext';

const LudoHome = () => {
    
const { customer } = GlobalContext();
const { ludostate, ludoUsrUpdate } = GlobalLudo();
const checkLudoBetStatus = useMemo(() => ludostate.ludoBetStatus, [ludostate]);

useEffect(() => {
ludoUsrUpdate(customer.length > 0 ? customer[0].customer : false);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[customer]);

return (
<>
{!checkLudoBetStatus ? <LudoStart /> : <LudoMain />}
</>
)
}

export default LudoHome;
