import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';
import AbMod from '../modals/AbMod';
import MarketBets from '../../allBets/MarketBets';
import { check_ab, start_ab } from './js/refresh';
import { runresult_ab, startResult_ab } from './js/result';
import { startSettle_ab, runSettle_ab } from './js/settle';
import { 
abClick,
cancelBet_ab,
minusAmt_ab,
plusAmt_ab,
changeAmt_ab,
betSend_ab,
stakeBet_ab,
allSid_ab,
pls_ab
 } from './js/betting';

const AndarBahar = () => {

const [load_ab, setLoad_Ab] = useState(true);
const [currab, setCurrAb] = useState([]);
const { customer } = GlobalContext();

const andLeft = () => {
var cardId = document.getElementById('CradsAndar');
cardId.scrollLeft -= 10;
}

const andRight = () => {
var cardId = document.getElementById('CradsAndar');
cardId.scrollLeft += 10;
}

const bahLeft = () => {
var cardId = document.getElementById('CradsBahar');
cardId.scrollLeft -= 10;
}

const bahRight = () => {
var cardId = document.getElementById('CradsBahar');
cardId.scrollLeft += 10;
}

useEffect(() => {
async function fetchAb() {
try {
const getData = await axios.get('/server/andarBahar_Data');
const resData = await getData.data;
if(resData.data !== undefined){
resData.data.t2.forEach(element => {
var data = {
sid : element.sid,
nat : element.nation
}

const findInd = allSid_ab.findIndex((o) => o.sid === element.sid && o.nat === element.nation);
if(findInd === -1){
allSid_ab.push(data);
}
});
setLoad_Ab(false);
setCurrAb(resData);
}
else{
setLoad_Ab(true);
setCurrAb([]);
}
} catch (error) {
console.log('frontend casino_andarBahar_data error : ' + error);
}
}

fetchAb();
},[]);

useEffect(() => {
check_ab();
runresult_ab();
startSettle_ab();
pls_ab(currab.data !== undefined ? currab.data.t1[0].mid : 0,'andar_bahar');
return () => {
clearInterval(start_ab);
clearInterval(startResult_ab);
clearInterval(runSettle_ab);
}
},[currab]);

if(load_ab){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='game-contents'>

{
currab.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>andar bahar</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_ab'>{currab.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='teamLiveCards'>
<div className='longDivCard'>
<span className='cardTeam'>andar</span>
<div className='abCradsWrap'>
<div className='forwardBtn' onClick={andLeft}><span><i className='fa fa-angle-left'></i></span></div>
<div className='longCrads' id='CradsAndar'></div>
<div className='forwardBtn' onClick={andRight}><span><i className='fa fa-angle-right'></i></span></div>
</div>
</div>

<div className='longDivCard'>
<span className='cardTeam'>bahar</span>
<div className='abCradsWrap'>
<div className='forwardBtn' onClick={bahLeft}><span><i className='fa fa-angle-left'></i></span></div>
<div className='longCrads' id='CradsBahar'></div>
<div className='forwardBtn' onClick={bahRight}><span><i className='fa fa-angle-right'></i></span></div>
</div>
</div>
</div>
</div>  

<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_ab'>{currab.data.t1[0].autotime}</span></div>   
</div> 

<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3053' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_ab'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_ab'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_ab'></span>
</div>
<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_ab'>back</span>
<span style={{display:'none'}} id='sec_ab'>match_odds</span>
<span style={{display:'none'}} id='sport_ab'>andar_bahar</span>
<span style={{display:'none'}} id='teamCas_ab'></span>
<span style={{display:'none'}} id='teamSid_ab'></span>
<span style={{display:'none'}} id='userCurr_ab'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_ab'>{currab.data.t1[0].mid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_ab()}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_ab' placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt_ab()}>-</span>
<input type='number' id='bet_amount_ab' placeholder='0' onChange={(e) => changeAmt_ab(e.target.value)} />
<span onClick={() => plusAmt_ab()}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_ab()} className='' id='betsPlace_ab'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_ab'>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet_ab(customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_ab()}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_ab()} className='' id='betsPlace2_ab'>place bet</button>
</div> 
</div>
</div>
</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>andar cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[0].sid}>{currab.data.t2[0].rate}</span>
<button id={'ab_Team_'+currab.data.t2[0].sid} onClick={() => abClick(currab.data.t2[0].sid,currab.data.t2[0].nation,currab.data.t2[0].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[0].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[0].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[0].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[1].sid}>{currab.data.t2[1].rate}</span>
<button id={'ab_Team_'+currab.data.t2[1].sid} onClick={() => abClick(currab.data.t2[1].sid,currab.data.t2[1].nation,currab.data.t2[1].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[1].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[1].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[1].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[2].sid}>{currab.data.t2[2].rate}</span>
<button id={'ab_Team_'+currab.data.t2[2].sid} onClick={() => abClick(currab.data.t2[2].sid,currab.data.t2[2].nation,currab.data.t2[2].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[2].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[2].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[2].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[3].sid}>{currab.data.t2[3].rate}</span>
<button id={'ab_Team_'+currab.data.t2[3].sid} onClick={() => abClick(currab.data.t2[3].sid,currab.data.t2[3].nation,currab.data.t2[3].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[3].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[3].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[3].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[4].sid}>{currab.data.t2[4].rate}</span>
<button id={'ab_Team_'+currab.data.t2[4].sid} onClick={() => abClick(currab.data.t2[4].sid,currab.data.t2[4].nation,currab.data.t2[4].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[4].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[4].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[4].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[5].sid}>{currab.data.t2[5].rate}</span>
<button id={'ab_Team_'+currab.data.t2[5].sid} onClick={() => abClick(currab.data.t2[5].sid,currab.data.t2[5].nation,currab.data.t2[5].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[5].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[5].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[5].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[6].sid}>{currab.data.t2[6].rate}</span>
<button id={'ab_Team_'+currab.data.t2[6].sid} onClick={() => abClick(currab.data.t2[6].sid,currab.data.t2[6].nation,currab.data.t2[6].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[6].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[6].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[6].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[7].sid}>{currab.data.t2[7].rate}</span>
<button id={'ab_Team_'+currab.data.t2[7].sid} onClick={() => abClick(currab.data.t2[7].sid,currab.data.t2[7].nation,currab.data.t2[7].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[7].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[7].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[7].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[8].sid}>{currab.data.t2[8].rate}</span>
<button id={'ab_Team_'+currab.data.t2[8].sid} onClick={() => abClick(currab.data.t2[8].sid,currab.data.t2[8].nation,currab.data.t2[8].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[8].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[8].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[8].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[9].sid}>{currab.data.t2[9].rate}</span>
<button id={'ab_Team_'+currab.data.t2[9].sid} onClick={() => abClick(currab.data.t2[9].sid,currab.data.t2[9].nation,currab.data.t2[9].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[9].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[9].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[9].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[10].sid}>{currab.data.t2[10].rate}</span>
<button id={'ab_Team_'+currab.data.t2[10].sid} onClick={() => abClick(currab.data.t2[10].sid,currab.data.t2[10].nation,currab.data.t2[10].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[10].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[10].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[10].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[11].sid}>{currab.data.t2[11].rate}</span>
<button id={'ab_Team_'+currab.data.t2[11].sid} onClick={() => abClick(currab.data.t2[11].sid,currab.data.t2[11].nation,currab.data.t2[11].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[11].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[11].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[11].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[12].sid}>{currab.data.t2[12].rate}</span>
<button id={'ab_Team_'+currab.data.t2[12].sid} onClick={() => abClick(currab.data.t2[12].sid,currab.data.t2[12].nation,currab.data.t2[12].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[12].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[12].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[12].sid}>0</span>
</div>

</div>
</div>

<div className='dtSec1'>
<div className='dtOddsTitle'><span>bahar cards </span></div>
<div className='dtRow'>
<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[13].sid}>{currab.data.t2[13].rate}</span>
<button id={'ab_Team_'+currab.data.t2[13].sid} onClick={() => abClick(currab.data.t2[13].sid,currab.data.t2[13].nation,currab.data.t2[13].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[13].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/1.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[13].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[13].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[14].sid}>{currab.data.t2[14].rate}</span>
<button id={'ab_Team_'+currab.data.t2[14].sid} onClick={() => abClick(currab.data.t2[14].sid,currab.data.t2[14].nation,currab.data.t2[14].rate)}>
<span id={'ab_TeamTx14_'+currab.data.t2[14].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/2.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[14].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[14].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[15].sid}>{currab.data.t2[15].rate}</span>
<button id={'ab_Team_'+currab.data.t2[15].sid} onClick={() => abClick(currab.data.t2[15].sid,currab.data.t2[15].nation,currab.data.t2[15].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[15].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/3.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[15].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[15].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[16].sid}>{currab.data.t2[16].rate}</span>
<button id={'ab_Team_'+currab.data.t2[16].sid} onClick={() => abClick(currab.data.t2[16].sid,currab.data.t2[16].nation,currab.data.t2[16].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[16].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/4.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[16].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[16].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[17].sid}>{currab.data.t2[17].rate}</span>
<button id={'ab_Team_'+currab.data.t2[17].sid} onClick={() => abClick(currab.data.t2[17].sid,currab.data.t2[17].nation,currab.data.t2[17].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[17].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/5.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[17].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[17].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[18].sid}>{currab.data.t2[18].rate}</span>
<button id={'ab_Team_'+currab.data.t2[18].sid} onClick={() => abClick(currab.data.t2[18].sid,currab.data.t2[18].nation,currab.data.t2[18].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[18].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/6.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[18].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[18].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[19].sid}>{currab.data.t2[19].rate}</span>
<button id={'ab_Team_'+currab.data.t2[19].sid} onClick={() => abClick(currab.data.t2[19].sid,currab.data.t2[19].nation,currab.data.t2[19].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[19].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/7.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[19].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[19].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[20].sid}>{currab.data.t2[20].rate}</span>
<button id={'ab_Team_'+currab.data.t2[20].sid} onClick={() => abClick(currab.data.t2[20].sid,currab.data.t2[20].nation,currab.data.t2[20].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[20].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/8.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[20].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[20].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[21].sid}>{currab.data.t2[21].rate}</span>
<button id={'ab_Team_'+currab.data.t2[21].sid} onClick={() => abClick(currab.data.t2[21].sid,currab.data.t2[21].nation,currab.data.t2[21].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[21].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/9.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[21].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[21].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[22].sid}>{currab.data.t2[22].rate}</span>
<button id={'ab_Team_'+currab.data.t2[22].sid} onClick={() => abClick(currab.data.t2[22].sid,currab.data.t2[22].nation,currab.data.t2[22].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[22].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/10.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[22].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[22].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[23].sid}>{currab.data.t2[23].rate}</span>
<button id={'ab_Team_'+currab.data.t2[23].sid} onClick={() => abClick(currab.data.t2[23].sid,currab.data.t2[23].nation,currab.data.t2[23].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[23].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/11.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[23].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[23].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[24].sid}>{currab.data.t2[24].rate}</span>
<button id={'ab_Team_'+currab.data.t2[24].sid} onClick={() => abClick(currab.data.t2[24].sid,currab.data.t2[24].nation,currab.data.t2[24].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[24].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/12.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[24].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[24].sid}>0</span>
</div>

<div className='colCards'>
<span className='dtRate' id={'ab_Rate_'+currab.data.t2[25].sid}>{currab.data.t2[25].rate}</span>
<button id={'ab_Team_'+currab.data.t2[25].sid} onClick={() => abClick(currab.data.t2[25].sid,currab.data.t2[25].nation,currab.data.t2[25].rate)}>
<span id={'ab_TeamTx1_'+currab.data.t2[25].sid}><img src='https://diamondexch.me/img/cards/andar_bahar1/13.jpg' alt='cards' /></span>
<span id={'ab_TeamTx2_'+currab.data.t2[25].sid}></span>
</button>
<span className='dtPls abPls' id={'ab_Pls_'+currab.data.t2[25].sid}>0</span>
</div>

</div>
</div>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_ab'></div>
</div>

<MarketBets eventid={currab.data !== undefined ? currab.data.t1[0].mid : 0} />
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>

<AbMod />
</>
)
}

export default AndarBahar;
