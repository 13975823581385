import React from 'react';
import Slider from '../extra/Slider';
import Footer from '../extra/Footer';
import AcceptAge from '../modals/AcceptAge';
import FrontPay from './FrontPay';
import CasinoScroll from './CasinoScroll';
import HomeContent from './HomeContent';

const HomePage = () => {
return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<Slider />
<div className='game-contents padding10'>
<FrontPay />
<CasinoScroll />
<HomeContent />
<Footer />
</div>

</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default HomePage;
