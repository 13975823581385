import React from 'react';
import { colors } from './assets/Colors';
import Dice from './Dice';
import Pocket from './Pocket';
import VerticalPath from './VerticalPath';
import HorizPath from './HorizPath';
import LudoCenter from './LudoCenter';
import { plot1Data, plot2Data, plot3Data, plot4Data } from './assets/plot';
import LudoBack from './LudoBack';
import LudoSound from './LudoSound';
import { GlobalLudo } from './assets/initialState';

const LudoMain = () => {

const { ludostate } = GlobalLudo();
const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
const parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);

return (
<>
<div className='ludo_gameDiv'>
<div className='ludoGameCon'>
<LudoSound />
{parseStore.winner !== null  && <LudoBack win={parseStore.winner} />}
<div className='ludoGameWrap'>
<div className='diceContainerWrap'>
<div className='diceContainer'>
<Dice color={colors.greenColor} player={2} disePos={'left'} data={parseStore.player2} ludoAutoPlay={parseStore.autoPlay} />
<Dice color={colors.yellowColor} player={3} disePos={'right'} data={parseStore.player3} ludoAutoPlay={parseStore.autoPlay} />
</div>
</div>
<div className='LudoBoardContainer'>
<div className='ludoVerticalObj'>
<Pocket
color={colors.greenColor} 
player={2} 
data={parseStore.player2}
ludoAutoPlay={parseStore.autoPlay}
/>
<VerticalPath color={colors.yellowColor} cells={plot3Data} />
<Pocket 
color={colors.yellowColor} 
player={3} 
data={parseStore.player3}
ludoAutoPlay={parseStore.autoPlay}
/>
</div>
<div className='ludoHorObj'>
<HorizPath color={colors.greenColor} cells={plot2Data} />
<LudoCenter
player1={parseStore.player1}
player2={parseStore.player2}
player3={parseStore.player3}
player4={parseStore.player4}
/>
<HorizPath color={colors.blueColor} cells={plot4Data} />
</div>
<div className='ludoVerticalObj'>
<Pocket 
color={colors.redColor} 
player={1} 
data={parseStore.player1}
ludoAutoPlay={parseStore.autoPlay}
/>
<VerticalPath color={colors.redColor} cells={plot1Data} />
<Pocket
color={colors.blueColor} 
player={4} 
data={parseStore.player4}
ludoAutoPlay={parseStore.autoPlay}
/>
</div>
</div>
<div className='diceContainerWrap'>
<div className='diceContainer'>
<Dice color={colors.redColor} player={1} disePos={'left'} data={parseStore.player1} ludoAutoPlay={parseStore.autoPlay} />
<Dice color={colors.blueColor} player={4} disePos={'right'} data={parseStore.player4} ludoAutoPlay={parseStore.autoPlay} />
</div>
</div>
</div>
</div>
</div>
</>
)
}


export default LudoMain;
