import React, { useEffect, useState } from 'react';
import DiceResult from '../../modals/DiceResult';
import { GlobalContext } from '../../../context/AppContext';
import MarketBets from '../../../allBets/MarketBets';
import { 
dice1Mid,
runTimerDice1,
startCouAutdice1,
spinRunDice1,
getResult_rollDice,
dice1Time,
dice2Time,
dice3Time,
settle_rollDice,
rollDiceClick,
cancelBet_rollDice,
stakeBet_rollDice,
betSend_rollDice,
playSoundDice
} from './js/main';

const Dice1 = () => {

const { customer, spoSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const [lockShow, setLockShow] = useState(false);

useEffect(() => {
dice1Mid();
runTimerDice1();
getResult_rollDice();
return () => {
clearInterval(startCouAutdice1);
clearTimeout(spinRunDice1);
clearTimeout(dice1Time);
clearTimeout(dice2Time);
clearTimeout(dice3Time);
settle_rollDice();
playSoundDice(false);
}
},[]);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
//console.log(checkLock);
if(checkLock.length > 0){
setLockShow(true);
}else{
setLockShow(false);
}
}
},[lockSport]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='game-contents'>

<div className='casino-wrap'>

{
lockShow === true && 
<div className='sports_lock' id='sports_lock2' style={{display: 'flex'}}>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>
}

<div className='casino-content'>
<div className='casino-headRow'>
<div className='col'><img src='/images/dices.png' alt='highImg' /><span>Roll Dice</span></div>
<div className='col'><span>Market Id:</span><span id='marketid_dice1'>0</span></div>
</div>

<div className='diceWrap'>
<div className='countdown-div'><span id='countstart_dice1'>0</span></div>
<div className='winTitSec'>
<span>winner</span>
<span id='winRollDice'>player1</span>
</div>
<div className='diceRow'>
<div className='colDice'>
<span>player 1</span>
<div className='diceGroup' id='dice1'>
<img src='/images/dice/dice1.png' alt='dice' id='diceImg1' className='diceImg1' />
<img src='/images/dice/dice2.png' alt='dice' id='diceImg2' className='diceImg2' />
<img src='/images/dice/dice3.png' alt='dice' id='diceImg3' className='diceImg3' />
<img src='/images/dice/dice4.png' alt='dice' id='diceImg4' className='diceImg4' />
<img src='/images/dice/dice5.png' alt='dice' id='diceImg5' className='diceImg5' />
<img src='/images/dice/dice6.png' alt='dice' id='diceImg6' className='diceImg6' />
</div>


</div>

<div className='colDice'>
<span>player 2</span>
<div className='diceGroup' id='dice2'>
<img src='/images/dice/dice1.png' alt='dice' className='diceImg1' />
<img src='/images/dice/dice2.png' alt='dice' className='diceImg2' />
<img src='/images/dice/dice3.png' alt='dice' className='diceImg3' />
<img src='/images/dice/dice4.png' alt='dice' className='diceImg4' />
<img src='/images/dice/dice5.png' alt='dice' className='diceImg5' />
<img src='/images/dice/dice6.png' alt='dice' className='diceImg6' />
</div>


</div>

{
    /*
<div className='colDice'>
<span>player 2</span>
<div className='diceGroup'>
<img src='/images/dice/dice6.png' alt='dice' id='dice2' />
</div>
</div>
    */
}

</div>
</div>

<div className='result-casino'>
<div className='col'><span>Result -</span></div>
<div className='col'>
<div className='resultcasDiv' id='resultRollDice'></div>
</div>
</div>

<div className='head_odds'>
<div className='oddsTitVir'>
<div className='FirstSkew'><span>winner</span></div>
<div className='BlankSkew'></div>
</div>
<div className='headTails_odds_row'>
<div className='lockRollDice'><i className='fa fa-lock'></i></div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(1,'player 1')}>
<span>player 1</span>
</button>
</div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(1,'draw')}>
<span>draw</span>
</button>
</div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(1,'player 2')}>
<span>player 2</span>
</button>
</div>
</div>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_rollDice1'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_rollDice1'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_rollDice1'></span>
</div>

<div className='betTeamSec'>
<span>selected team : <span id='teamCas_rollDice1'></span></span>
</div>

<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_rollDice1'>back</span>
<span style={{display:'none'}} id='sec_rollDice1'>match_odds</span>
<span style={{display:'none'}} id='sport_rollDice1'>roll_dice</span>
<span style={{display:'none'}} id='teamSid_rollDice1'>1</span>
<span style={{display:'none'}} id='userCurr_rollDice1'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_dice1'>0</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(1)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_rollDice1' placeholder='1.98' disabled />
<span>+</span>
</div>

<div>
<span>-</span>
<input type='number' id='bet_amount_rollDice1' placeholder='0' disabled />
<span>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(1)} className='' id='betsPlace_rollDice1'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_rollDice'>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(1,customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100)}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(1)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(1)} className='' id='betsPlace2_rollDice1'>place bet</button>
</div> 
</div>
</div>
</div>
</div>
</div>

<div className='head_odds'>
<div className='oddsTitVir'>
<div className='FirstSkew'><span>player 1 odd/even</span></div>
<div className='BlankSkew'></div>
</div>
<div className='headTails_odds_row'>
<div className='lockRollDice'><i className='fa fa-lock'></i></div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(2,'player 1 odd')}>
<span>odd</span>
</button>
</div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(2,'player 1 even')}>
<span>even</span>
</button>
</div>
</div>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_rollDice2'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_rollDice2'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_rollDice2'></span>
</div>

<div className='betTeamSec'>
<span>selected team : <span id='teamCas_rollDice2'></span></span>
</div>

<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_rollDice2'>back</span>
<span style={{display:'none'}} id='sec_rollDice2'>match_odds</span>
<span style={{display:'none'}} id='sport_rollDice2'>roll_dice</span>
<span style={{display:'none'}} id='teamSid_rollDice2'>1</span>
<span style={{display:'none'}} id='userCurr_rollDice2'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_dice2'>0</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(2)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_rollDice2' placeholder='1.98' disabled />
<span>+</span>
</div>

<div>
<span>-</span>
<input type='number' id='bet_amount_rollDice2' placeholder='0' disabled />
<span>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(2)} className='' id='betsPlace_rollDice2'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_rollDice2'>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(2,customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100)}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(2)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(2)} className='' id='betsPlace2_rollDice2'>place bet</button>
</div> 
</div>
</div>
</div>
</div>
</div>

<div className='head_odds'>
<div className='oddsTitVir'>
<div className='FirstSkew'><span>player 2 odd/even</span></div>
<div className='BlankSkew'></div>
</div>
<div className='headTails_odds_row'>
<div className='lockRollDice'><i className='fa fa-lock'></i></div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(3,'player 2 odd')}>
<span>odd</span>
</button>
</div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(3,'player 2 even')}>
<span>even</span>
</button>
</div>
</div>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_rollDice3'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_rollDice3'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_rollDice3'></span>
</div>

<div className='betTeamSec'>
<span>selected team : <span id='teamCas_rollDice3'></span></span>
</div>

<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_rollDice3'>back</span>
<span style={{display:'none'}} id='sec_rollDice3'>match_odds</span>
<span style={{display:'none'}} id='sport_rollDice3'>roll_dice</span>
<span style={{display:'none'}} id='teamSid_rollDice3'>1</span>
<span style={{display:'none'}} id='userCurr_rollDice3'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_dice3'>0</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(3)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_rollDice3' placeholder='1.98' disabled />
<span>+</span>
</div>

<div>
<span>-</span>
<input type='number' id='bet_amount_rollDice3' placeholder='0' disabled />
<span>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(3)} className='' id='betsPlace_rollDice3'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_rollDice3'>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(3,customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100)}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(3)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(3)} className='' id='betsPlace2_rollDice3'>place bet</button>
</div> 
</div>
</div>
</div>
</div>
</div>

<div className='head_odds'>
<div className='oddsTitVir'>
<div className='FirstSkew'><span>draw odd/even</span></div>
<div className='BlankSkew'></div>
</div>
<div className='headTails_odds_row'>
<div className='lockRollDice'><i className='fa fa-lock'></i></div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(4,'draw odd')}>
<span>odd</span>
</button>
</div>
<div className='col'>
<span className='headRate'>1.98</span>
<button className='head_oddsBtn rollDiceBtn' onClick={() => rollDiceClick(4,'draw even')}>
<span>even</span>
</button>
</div>
</div>

<div style={{display:'none'}} className='placeBetCasino' id='placeBetCasino_rollDice4'>
<div style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_rollDice4'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_rollDice4'></span>
</div>

<div className='betTeamSec'>
<span>selected team : <span id='teamCas_rollDice4'></span></span>
</div>

<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_rollDice4'>back</span>
<span style={{display:'none'}} id='sec_rollDice4'>match_odds</span>
<span style={{display:'none'}} id='sport_rollDice4'>roll_dice</span>
<span style={{display:'none'}} id='teamSid_rollDice4'>1</span>
<span style={{display:'none'}} id='userCurr_rollDice4'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_dice4'>0</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(4)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_rollDice4' placeholder='1.98' disabled />
<span>+</span>
</div>

<div>
<span>-</span>
<input type='number' id='bet_amount_rollDice4' placeholder='0' disabled />
<span>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(4)} className='' id='betsPlace_rollDice4'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_rollDice4'>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 5000 : 5000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 10000 : 10000 / 100)}</button>
<button onClick={() => stakeBet_rollDice(4,customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100))}>{customer.length > 0 && (customer[0].currency === 'inr' ? 15000 : 15000 / 100)}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_rollDice(4)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_rollDice(4)} className='' id='betsPlace2_rollDice4'>place bet</button>
</div> 
</div>
</div>
</div>
</div>
</div>

<div className='bbetsDiv'>
<MarketBets eventid='0' />
</div>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>

<DiceResult />
</>
)
}

export default Dice1;
