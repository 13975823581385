import React from 'react';
import $ from 'jquery';
import InPlay from './today/InPlay';
import InPlay1 from './tomorrow/InPlay1';
import InPlay2 from './yesterday/InPlay2';
import Footer from '../extra/Footer';

const MainInplay = () => {

const todayEvent = () => {
$('.todayInplay').show();
$('.tomorrowInplay').hide();
$('.yesterdayInplay').hide();
}

const tomorrowEvent = () => {
$('.todayInplay').hide();
$('.tomorrowInplay').show();
$('.yesterdayInplay').hide();
}

const yesterdayEvent = () => {
$('.todayInplay').hide();
$('.tomorrowInplay').hide();
$('.yesterdayInplay').show();   
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='game-contents padding10'>
<div className='inplayBtns-div'>
<button onClick={() => todayEvent()}>today</button>
<button onClick={() => tomorrowEvent()}>tomorrow</button>
<button onClick={() => yesterdayEvent()}>upcoming</button>
</div>

<div className='todayInplay'><InPlay /></div>
<div className='tomorrowInplay hideElem'><InPlay1 /></div>
<div className='yesterdayInplay hideElem'><InPlay2 /></div>

<Footer />
</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default MainInplay;
