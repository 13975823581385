import React, {useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';
import CasinoCont from './CasinoCont';
import VirtualCont from './VirtualCont';

const MultiApi = '/server/customer-multimarket';

const HomeContent = () => {

const {customer, cicList, soccList, tennList, multimarket, GetMultiList, spoSett, gameSett, cricCompAll} = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const Navigate = useNavigate();
const [currCricList, setCurrCricList] = useState([]);
const [currSoccList, setCurrSoccList] = useState([]);
const [currTennList, setCurrTennList] = useState([]);

const pinEvent = async(eid,gmid,mid) => {
if (customer.length > 0){
//alert(eid +' -- '+ gmid +' -- '+ mid);
try {
const sendData = await axios.post('/server/multimarkets', {customer:customer[0].customer, eid:eid, gmid:gmid, mid:mid});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('pin event successfully');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else if(resData === 'removed'){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('pin removed successfully');
GetMultiList(`${MultiApi}`);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);   
}
} catch (error) {
console.log('frontend cricket multiadd-api error: ' + error);
}
}
else{
$('#AcceptAgeModal').fadeIn();
}
}

const pinEventData = cicList.filter(i => multimarket.some(j => parseFloat(i.gameid) === parseFloat(j.gmid) && parseFloat(i.marketid) === parseFloat(j.mid)));
const noPinEventData = cicList.filter(i => !multimarket.some(j => parseFloat(i.gameid) === parseFloat(j.gmid) && parseFloat(i.marketid) === parseFloat(j.mid)));
const pinEventDataSocc = soccList.filter(i => multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));
const noPinEventDataSocc = soccList.filter(i => !multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));
const pinEventDataTenn = tennList.filter(i => multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));
const noPinEventDataTenn = tennList.filter(i => !multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));

const openMarket = (eventid, marketid,eid) => {
if(eid === 4){
Navigate('/market-4/'+eventid+'/'+marketid);
}
else if(eid === 1){
Navigate('/market-1/'+eventid+'/'+marketid);
}
else{
Navigate('/market-2/'+eventid+'/'+marketid);   
}
}

useEffect(() => {
if(cicList.length > 0){
setCurrCricList(cicList.filter((e) => e.inplay === true || e.inplay === 'true'));
}
if(soccList.length > 0){
setCurrSoccList(soccList.filter((e) => e.iplay === true || e.iplay === 'true'));
}
if(tennList.length > 0){
setCurrTennList(tennList.filter((e) => e.iplay === true || e.iplay === 'true'));
}
},[cicList, soccList, tennList]);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
const checkLockSocc = lockSport.filter((e) => e.sport === 'soccer');
const checkLockTenn = lockSport.filter((e) => e.sport === 'tennis');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lockCric').css({display: 'flex'});
}else{
$('#sports_lockCric').hide();
}

if(checkLockSocc.length > 0){
$('#sports_lockSocc').css({display: 'flex'});
}else{
$('#sports_lockSocc').hide();
}

if(checkLockTenn.length > 0){
$('#sports_lockTenn').css({display: 'flex'});
}else{
$('#sports_lockTenn').hide();
}
}
},[lockSport]);

useEffect(() => {
if(cicList.length > 0){
cricCompAll.filter((e) => gameSett.find((o) => parseFloat(e.comp_id) === parseFloat(o.eventid) && o.sport === 'cricket')).forEach((k) => {
$('#GameLock_'+k.event_id).css({display: 'flex'});
});
}
if(gameSett.length > 0 && soccList.length > 0){
soccList.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'soccer')).forEach((k) => {
$('#GameLock_'+k.cid+k.mid).css({display: 'flex'});
});
}
if(gameSett.length > 0 && tennList.length > 0){
tennList.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'tennis')).forEach((k) => {
$('#GameLock_'+k.cid+k.mid).css({display: 'flex'});
});
}
},[gameSett, cricCompAll, cicList, soccList, tennList]);

return (
<>
<div className='homeSportDiv'>
<div className='highlightdIV'><img src='/images/cricketW.png' alt='highImg' /> <span>Cricket Sports</span></div>

<div className='sports-page'>

<div className='sports_lock' id='sports_lockCric'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>X</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
currCricList.length > 0 ? currCricList.map((data, index) => {
var fTrue = '';
var tvTrue = '';
var inPlay = '';
var ename = data.eventname.split('/');
var splitDate = ename[1].replaceAll('(ist)', '').replaceAll('am', ' am').replaceAll('pm', ' pm');
var eventTime = '';
var timeParse = Date.parse(splitDate);
if(data.f === 'true'){
fTrue = <span className='f-avail'><img src='../images/letter-f.png' alt='sportsIco' /></span>
}
if(data.tv === 'true'){
tvTrue = <span className='tv-avail'><img src='../images/tv.png' alt='sportsIco' /></span>
}
if(data.inplay === 'true'){
inPlay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString()}</span>;
}


return(
<tbody key={index}>
<tr>
<td className='GameLock' id={'GameLock_'+data.gameid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gameid, data.marketid,4)} className='event-name'>{ename[0].trim()}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{inPlay}{fTrue}{tvTrue}</span></td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back1}</span>
<span className='lay-sports-page'>{data.lay1}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back11}</span>
<span className='lay-sports-page'>{data.lay11}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back12}</span>
<span className='lay-sports-page'>{data.lay12}</span>
</td>
{
pinEventData.length > 0 && pinEventData.filter(h => h.gameid === data.gameid).map((data2, index2) => {
return (
<td key={index2} onClick={() => pinEvent('4', data2.gameid, data2.marketid)}><img className='pinImg' src='../images/pin-green.png' alt='pngIco' /></td>
)
})
}

{
noPinEventData.length > 0 && noPinEventData.filter(h => h.gameid === data.gameid).map((data3, index3) => {
return (
<td key={index3} onClick={() => pinEvent('4', data3.gameid, data3.marketid)}><img className='pinImg' src='../images/pin.png' alt='pngIco' /></td>
)
})
}
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='6'><span className='homeSpoNoRec'>there are no any live events...</span></td>
</tr>
</tbody>
}
</table>
</div>
</div>

<div className='homeSportDiv margintop15'>
<div className='highlightdIV'><img src='/images/goal.png' alt='highImg' /> <span>Soccer Sports</span></div>

<div className='sports-page'>
    
<div className='sports_lock' id='sports_lockSocc'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel sportsPageTable2'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>X</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
currSoccList.length > 0 ? currSoccList.map((data, index) => {
var iplay = '';
var eventTime = '';
var timeParse = Date.parse(data.stime);
if(data.iplay === true || data.iplay === 'true'){
iplay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString()}</span>;
}
return (
<tbody key={index}>

<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gmid, data.mid,1)} className='event-name'>{data.ename.trim().replace('-', 'v')}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{iplay}</span></td>
{
data.section.length > 0 && data.section.map((data2, index2) => {
return (
<td className='counts-rates' key={index2}>
<span className='back-sports-page'>{data2.odds[0].otype === 'back' ? data2.odds[0].odds : data2.odds[1].odds}</span>
<span className='lay-sports-page'>{data2.odds[1].otype === 'lay' ? data2.odds[1].odds : data2.odds[0].odds}</span>
</td>
)
})   
}

{
pinEventDataSocc.length > 0 && pinEventDataSocc.filter(h => h.gmid === data.gmid).map((data2, index2) => {
return (
<td key={index2} onClick={() => pinEvent(data2.etid, data2.gmid, data2.mid)}><img className='pinImg' src='../images/pin-green.png' alt='pngIco' /></td>
)
})
}

{
noPinEventDataSocc.length > 0 && noPinEventDataSocc.filter(h => h.gmid === data.gmid).map((data3, index3) => {
return (
<td key={index3} onClick={() => pinEvent(data3.etid, data3.gmid, data3.mid)}><img className='pinImg' src='../images/pin.png' alt='pngIco' /></td>
)
})
}
</tr>
</tbody>
)
})  
:
<tbody>
<tr>
<td colSpan='6'><span className='homeSpoNoRec'>there are no any live events...</span></td>
</tr>
</tbody>
}
</table>
</div>
</div>

<div className='homeSportDiv margintop15'>
<div className='highlightdIV'><img src='/images/beach.png' alt='highImg' /> <span>Tennis Sports</span></div>

<div className='sports-page'>

<div className='sports_lock' id='sports_lockTenn'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel sportsPageTable3'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
currTennList.length > 0 ? currTennList.map((data, index) => {
var iplay = '';
var eventTime = '';
var timeParse = Date.parse(data.stime);
if(data.iplay === true || data.iplay === 'true'){
iplay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString()}</span>;
}
return (
<tbody key={index}>

<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span className='event-name' onClick={() => openMarket(data.gmid, data.mid,2)}>{data.ename.trim().replace('-', 'v')}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{iplay}</span></td>
{
data.section.length > 0 && data.section.map((data2, index2) => {
return (
<td className='counts-rates' key={index2}>
<span className='back-sports-page'>{data2.odds[0].otype === 'back' ? data2.odds[0].odds : data2.odds[1].odds}</span>
<span className='lay-sports-page'>{data2.odds[1].otype === 'lay' ? data2.odds[1].odds : data2.odds[0].odds}</span>
</td>
)
})   
}

{
pinEventDataTenn.length > 0 && pinEventDataTenn.filter(h => h.gmid === data.gmid).map((data2, index2) => {
return (
<td key={index2} onClick={() => pinEvent(data2.etid, data2.gmid, data2.mid)}><img className='pinImg' src='../images/pin-green.png' alt='pngIco' /></td>
)
})
}

{
noPinEventDataTenn.length > 0 && noPinEventDataTenn.filter(h => h.gmid === data.gmid).map((data3, index3) => {
return (
<td key={index3} onClick={() => pinEvent(data3.etid, data3.gmid, data3.mid)}><img className='pinImg' src='../images/pin.png' alt='pngIco' /></td>
)
})
}
</tr>
</tbody>
)
})
:
<tbody>
<tr>
<td colSpan='6'><span className='homeSpoNoRec'>there are no any live events...</span></td>
</tr>
</tbody>  
}
</table>
</div>
</div>

<div className='homeSportDiv margintop15'>
<div className='highlightdIV'><img src='/images/casino.png' alt='highImg' /> <span>Live Casino</span></div>
<CasinoCont />
</div>

<div className='homeSportDiv margintop15'>
<div className='highlightdIV'><img src='/images/virtualCasino.png' alt='highImg' /> <span>Live Virtual Casino</span></div>
<VirtualCont />
</div>
</>
)
}

export default HomeContent;
