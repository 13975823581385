import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const stakeApi = '/server/active-customer';

const Stake = () => {

const { customer, GetUserList } = GlobalContext();

const updateStake = async() => {
var stake1 = $('#stake1').val();
var stake2 = $('#stake2').val();
var stake3 = $('#stake3').val();
var stake4 = $('#stake4').val();
var stake5 = $('#stake5').val();
var stake6 = $('#stake6').val();
var stake7 = $('#stake7').val();
var stake8 = $('#stake8').val();

//alert(stake1 +' -- '+ stake2 +' -- '+ stake3 +' -- '+ stake4 +' -- '+ stake5 +' -- '+ stake6 +' -- '+ stake7 +' -- '+ stake8);
if(stake1 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake1 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake2 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake2 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake3 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake3 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake4 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake4 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake5 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake5 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake6 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake6 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake7 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake7 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
if(stake8 === ''){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('stake8 required');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000); 
return false;
}
else{
$('#update-stake').addClass('medGrey_button').removeClass('medGreen_button').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/update-stake', {
stake1: stake1,
stake2: stake2,
stake3: stake3,
stake4: stake4,
stake5: stake5,
stake6: stake6,
stake7: stake7,
stake8: stake8
});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('stake updated successfully');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#update-stake').addClass('medGreen_button').removeClass('medGrey_button').removeAttr('disabled');
GetUserList(`${stakeApi}`);
},1000); 
return false;    
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
$('#update-stake').addClass('medGreen_button').removeClass('medGrey_button').removeAttr('disabled');
},1000);  
}
} catch (error) {
console.log('frontend stake-update-api error: ' + error); 
}
}
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/proof-of-stake.png' alt='highImg' /> <span>stake setting</span></div>

{
customer.length > 0 &&
<div className='four-inputs-row'>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake1 *</label>
<input type='number' id='stake1' placeholder='enter stake1' defaultValue={customer[0].stake1} />
</div>
</div>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake2 *</label>
<input type='number' id='stake2' placeholder='enter stake2' defaultValue={customer[0].stake2} />
</div>
</div>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake3 *</label>
<input type='number' id='stake3' placeholder='enter stake3' defaultValue={customer[0].stake3} />
</div>
</div>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake4 *</label>
<input type='number' id='stake4' placeholder='enter stake4' defaultValue={customer[0].stake4} />
</div>
</div>

<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake5 *</label>
<input type='number' id='stake5' placeholder='enter stake5' defaultValue={customer[0].stake5} />
</div>
</div>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake6 *</label>
<input type='number' id='stake6' placeholder='enter stake6' defaultValue={customer[0].stake6} />
</div>
</div>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake7 *</label>
<input type='number' id='stake7' placeholder='enter stake7' defaultValue={customer[0].stake7} />
</div>
</div>
<div className='col-four-inputs'>
<div className='input-div-md'>
<label>stake8 *</label>
<input type='number' id='stake8' placeholder='enter stake8' defaultValue={customer[0].stake8} />
</div>
</div>
<div className='submitBtns_RightDiv'>
<button id='update-stake' className='medGreen_button' onClick={() => updateStake()}>update stake</button>
</div>
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Stake;
