import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const allBetsApi = '/server/all-bets';

const MyBets = () => {

const navigate = useNavigate();
const { allBetsData, getAllBets } = GlobalContext();
const [currBets, setCurrBets] = useState([]);
const [currBetsEvent, setCurrBetsEvents] = useState([]);

const openBetsSport = (index,sport) => {

for(let i = 0; i < currBets.length; i++){
if(i === index){
$('#sport_'+i).show();
}
else{
$('#sport_'+i).hide();   
}
}

const findEvent = [];
allBetsData.filter((e) => e.sport === sport).forEach((ex) => {
var data = {
eventName: ex.eventName
}

const findInd = findEvent.findIndex((o) => o.eventName === ex.eventName);
if(findInd === -1){
findEvent.push(data);
}

});
setCurrBetsEvents(findEvent);
}

const openBetEvent = (sport,event) => {
navigate('/my_bets/event', {state:{sport: sport, eventName: event}})
}

useEffect(() => {
getAllBets(allBetsApi);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

useEffect(() => {
if(allBetsData.length > 0){
var findSports = [];
allBetsData.forEach((e) => {
var data = {
sport: e.sport
}

const findInd = findSports.findIndex((o) => o.sport === e.sport);
if(findInd === -1){
findSports.push(data);
}
});
setCurrBets(findSports);
}
},[allBetsData]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/dollar.png' alt='highImg' /> <span>my bets</span></div>

<div className='simple-div'>
{
currBets.length > 0 ?
<ul className='MybetsaUl'>
{
currBets.map((data, index) => {
return (
<li className='MybetsaUlLi' key={index}><span className='myBetsSpan' onClick={() => openBetsSport(index,data.sport)}>{data.sport} <i className='fa fa-caret-down rightIcon'></i></span>
<ul className='MybetsaUl2' id={'sport_'+index}>
{
currBetsEvent.length > 0 && currBetsEvent.map((data2, index2) => {
return (
<li key={index2} onClick={() => openBetEvent(data.sport, data2.eventName)}>{data2.eventName}</li>
)
})
}
</ul>
</li>
)
})
}
</ul>
:
<div className='noMyBets'>
<img src='/images/close64.png' alt='close64' />
<span>no available bets...</span>
</div>
}
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default MyBets;
