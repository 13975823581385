import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const allBetsApi = '/server/all-bets';

const EventBets = () => {

const location = useLocation();
const { allBetsData, getAllBets } = GlobalContext();
const [currEventsSec, setCurrEventsSec] = useState([]);
const [activeSec, setActiveSec] = useState([]);

const openBetSec = (index,sec) => {
if(currEventsSec.length > 0){
for(let i = 0; i < currEventsSec.length; i++) {
if(i === index){
$('#myBetsTab_'+i).show();
}
else{
$('#myBetsTab_'+i).hide();  
}
}
}
    
setActiveSec(allBetsData.filter((e) => e.sport === (location.state !== null ? location.state.sport : null) && e.eventName === (location.state !== null ? location.state.eventName : null) && e.sec === sec));
}

useEffect(() => {
getAllBets(allBetsApi);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

useEffect(() => {
if(allBetsData.length > 0){
var findSec = [];
allBetsData.filter((e) => e.sport === (location.state !== null ? location.state.sport : null) && e.eventName === (location.state !== null ? location.state.eventName : null)).forEach((e) => {
var data = {
sec: e.sec
}

const findInd = findSec.findIndex((o) => o.sec === e.sec);
if(findInd === -1){
findSec.push(data);  
}
});
setCurrEventsSec(findSec);
}
}, [allBetsData, location]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/balls-sports.png' alt='highImg' /> <span>{location.state !== null ? location.state.eventName : 'no record'}</span></div>

<div className='simple-div'>
<ul className='MybetsaUl'>
{
currEventsSec.length > 0 && currEventsSec.map((data, index) => {
return (
<li className='MybetsaUlLi' key={index}><span className='myBetsSpan' onClick={() => openBetSec(index,data.sec)}>{data.sec} <i className='fa fa-caret-down rightIcon'></i></span>
<div className='myBetsTab' id={'myBetsTab_'+index}>
<table className='myBetsTabHis'>
<thead>
<tr>
<th style={{textAlign: 'left'}}>team</th>
<th>odds</th>
<th>stake</th>
</tr>
</thead>
{
activeSec.length > 0 && activeSec.map((data2, index2) => {
if(data2.bettype === 'back') {
return(
<tbody key={index2}>
<tr className='liveBack_tr'>
<td style={{textAlign: 'left'}}>
<div className='gridBox'>
<div>    
<span className='gridBoxBack'>
{
data2.sec === 'fancy' || data2.sec === 'over' || data2.sec === 'oddeven'
|| data2.sec === 'meter' || data2.sec === 'line' || data2.sec === 'khado'
|| data2.sec === 'ball' || data2.sec === 'fancy1' ? 'yes' : data2.bettype 
}
</span>
</div>
<div>
<span className='gridBoxTxt'>{data2.team}</span>
<span className='gridBoxTxt2'>{data2.sec}</span>
</div>
</div>
</td>
<td className='conBets'>{data2.betRate}</td>
<td className='conBets'>{parseFloat(data2.betamount.toFixed(2)).toLocaleString()}</td>
</tr>
<tr className='liveBack_tr2'>
<td style={{textAlign: 'left', width: '50%'}}><span className='dateTime_sm'>date time : {data2.bet_dateTime}</span></td>
<td colSpan='2' style={{textAlign: 'right', width: '50%'}}><span className='dateTime_sm'>eventid : {data2.eventid}</span></td>
</tr>
</tbody>
)
}
else{
return (
<tbody key={index2}>
<tr className='liveLay_tr'>
<td style={{textAlign: 'left'}}>
<div className='gridBox'>
<div>    
<span className='gridBoxLay'>
{
data2.sec === 'fancy' || data2.sec === 'over' || data2.sec === 'oddeven'
|| data2.sec === 'meter' || data2.sec === 'line' || data2.sec === 'khado'
|| data2.sec === 'ball' || data2.sec === 'fancy1' ? 'no' : data2.bettype 
}
</span>
</div>
<div>
<span className='gridBoxTxt'>{data2.team}</span>
<span className='gridBoxTxt2'>{data2.sec}</span>
</div>
</div>
</td>
<td className='conBets'>{data2.betRate}</td>
<td className='conBets'>{parseFloat(data2.betamount.toFixed(2)).toLocaleString()}</td>
</tr>
<tr className='liveLay_tr2'>
<td style={{textAlign: 'left', width: '50%'}}><span className='dateTime_sm'>date time : {data2.bet_dateTime}</span></td>
<td colSpan='2' style={{textAlign: 'right', width: '50%'}}><span className='dateTime_sm'>eventid : {data2.eventid}</span></td>
</tr>
</tbody>
)
}
})
}
</table>
</div>
</li>
)
})
}
</ul>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default EventBets;
