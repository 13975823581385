import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const VirtualCont = () => {

const {customer, spoSett} = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const Navigate = useNavigate();

const headTail = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/head-tails');   
}
}
else{
Navigate('/head-tails');
}
}
else{
$('#AcceptAgeModal').show();
}        
}

const lucky0to9 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/lucky09');   
}
}
else{
Navigate('/lucky09');
}
}
else{
$('#AcceptAgeModal').show();
}       
}

const dice1 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/dice1');   
}
}
else{
Navigate('/dice1');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const ludoking = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/ludoking');   
}
}
else{
Navigate('/ludoking');
}
}
else{
$('#AcceptAgeModal').show();
}  
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lockVir').css({display: 'flex'});
}else{
$('#sports_lockVir').hide();
}
}
},[lockSport]);

return (
<>
<div className='virtualDataAll'>
<div className='sports_lock' id='sports_lockVir'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<div className='rowsFours'>
<div className='colsFourSm colPointer' onClick={headTail}>
<img src='/images/casinoImg/headTailsMd.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={lucky0to9}>
<img src='/images/casinoImg/lucky_09Md.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={dice1}>
<img src='/images/casinoImg/rollDiceMd.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={ludoking}>
<img src='/images/casinoImg/ludoKingMd.png' alt='casino_img' />
</div>

</div>
</div>
</>
)
}

export default VirtualCont;
