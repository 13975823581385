import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import Footer from '../extra/Footer';

const MultiMarket = () => {

const { cicList, soccList, tennList, multimarket, spoSett, gameSett, customer, cricCompAll } = GlobalContext();
const [lockSport, setLockSport] = useState([]);

const multiData = cicList.filter(i => multimarket.some(j => parseFloat(i.gameid) === parseFloat(j.gmid) && parseFloat(i.marketid) === parseFloat(j.mid)));
const multiDataSoc = soccList.filter(i => multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));
const multiDataTenn = tennList.filter(i => multimarket.some(j => parseFloat(i.gmid) === parseFloat(j.gmid) && parseFloat(i.mid) === parseFloat(j.mid) && parseInt(i.etid) === parseInt(j.eid)));
const Navigate = useNavigate();

const multiCric = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/cricket');   
}
}
else{
Navigate('/cricket');
}
}

const multiSocc = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'soccer');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/soccer');   
}
}
else{
Navigate('/soccer');
}
}

const multiTenn = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'tennis');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/tennis');   
}
}
else{
Navigate('/tennis');
}
}

const openMarket = (eventid,marketid,eid) => {
if(eid === 4){
Navigate('/market-4/'+eventid+'/'+marketid);
}
else if(eid === 1){
Navigate('/market-1/'+eventid+'/'+marketid);
}
else if(eid === 2){
Navigate('/market-2/'+eventid+'/'+marketid);
}
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.admin)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(multiData.length > 0){
const checkData = lockSport.filter((e) => e.sport === 'cricket');
if(checkData.length > 0){
$('#sports_locksm4').css({display: 'flex'});
}
}

if(multiDataSoc.length > 0){
const checkData = lockSport.filter((e) => e.sport === 'soccer');
if(checkData.length > 0){
$('#sports_locksm1').css({display: 'flex'});
}
}

if(multiDataTenn.length > 0){
const checkData = lockSport.filter((e) => e.sport === 'tennis');
if(checkData.length > 0){
$('#sports_locksm2').css({display: 'flex'});
}
}

},[lockSport, multiData, multiDataSoc, multiDataTenn]);

useEffect(() => {
if(gameSett.length > 0){
if(multiData.length > 0){
cricCompAll.filter((e) => gameSett.find((o) => parseFloat(e.comp_id) === parseFloat(o.eventid) && o.sport === 'cricket')).forEach((k) => {
$('#GameLock_'+k.event_id).css({display: 'flex'});
});
}

if(multiDataSoc.length > 0){
multiDataSoc.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'soccer')).forEach((k) => {
$('#GameLock_'+k.cid+k.mid).css({display: 'flex'});
});
}

if(multiDataTenn.length > 0){
multiDataTenn.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'tennis')).forEach((k) => {
$('#GameLock_'+k.cid+k.mid).css({display: 'flex'});
});
}
}
},[gameSett, multiData, cricCompAll, multiDataSoc, multiDataTenn]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='game-contents padding10'>
{
multiData.length > 0 &&
<div>
<div className='multimarketdIV'><img src='/images/cricket.png' alt='highImg' /> <span>cricket multimarket</span></div>

<div className='sports-page'>

<div className='sports_lockSm' id='sports_locksm4'>
<span>sport is locked <i className='fa fa-lock'></i></span>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>X</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
multiData.map((data, index) => {
var fTrue = '';
var tvTrue = '';
var inPlay = '';
var ename = data.eventname.split('/');
var splitDate = ename[1].replaceAll('(ist)', '').replaceAll('am', ' am').replaceAll('pm', ' pm');
var eventTime = '';
var timeParse = Date.parse(splitDate);
if(data.f === 'true'){
fTrue = <span className='f-avail'><img src='../images/letter-f.png' alt='sportsIco' /></span>
}
if(data.tv === 'true'){
tvTrue = <span className='tv-avail'><img src='../images/tv.png' alt='sportsIco' /></span>
}
if(data.inplay === 'true'){
inPlay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString('en-IN')}</span>;
}
return(
<tbody key={index}>
<tr>
<td className='GameLock' id={'GameLock_'+data.gameid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gameid,data.marketid,4)} className='event-name'>{ename[0].trim()}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{inPlay}{fTrue}{tvTrue}</span></td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back1}</span>
<span className='lay-sports-page'>{data.lay1}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back11}</span>
<span className='lay-sports-page'>{data.lay11}</span>
</td>
<td className='counts-rates'>
<span className='back-sports-page'>{data.back12}</span>
<span className='lay-sports-page'>{data.lay12}</span>
</td>
</tr>
</tbody>
)
})
}
</table>
</div>
</div>
}

{
multiDataSoc.length > 0 &&
<div>
<div className='multimarketdIV'><img src='/images/soccer.png' alt='highImg' /> <span>soccer multimarket</span></div>

<div className='sports-page'>

<div className='sports_lockSm' id='sports_locksm1'>
<span>sport is locked <i className='fa fa-lock'></i></span>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>X</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
multiDataSoc.map((data, index) => {
var iplay = '';
var eventTime = '';
var timeParse = Date.parse(data.stime);
if(data.iplay === true || data.iplay === 'true'){
iplay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString('en-IN')}</span>;
}
return (
<tbody key={index}>
<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gmid,data.mid,1)} className='event-name'>{data.ename.trim().replace('-', 'v')}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{iplay}</span></td>
{
data.section.length > 0 && data.section.map((data2, index2) => {
return (
<td className='counts-rates' key={index2}>
<span className='back-sports-page'>{data2.odds[0].otype === 'back' ? data2.odds[0].odds : data2.odds[1].odds}</span>
<span className='lay-sports-page'>{data2.odds[1].otype === 'lay' ? data2.odds[1].odds : data2.odds[0].odds}</span>
</td>
)
})   
}
</tr>
</tbody>
)
})  
}
</table>
</div>
</div>
}

{
multiDataTenn.length > 0 &&
<div>
<div className='multimarketdIV'><img src='/images/tennis.png' alt='highImg' /> <span>tennis multimarket</span></div>

<div className='sports-page'>

<div className='sports_lockSm' id='sports_locksm2'>
<span>sport is locked <i className='fa fa-lock'></i></span>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>2</th>
<th></th>
</tr>
</thead>
{
multiDataTenn.map((data, index) => {
var iplay = '';
var eventTime = '';
var timeParse = Date.parse(data.stime);
if(data.iplay === true || data.iplay === 'true'){
iplay = <span className='inplay-avail'><img src='../images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString('en-IN')}</span>;
}
return (
<tbody key={index}>
<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gmid,data.mid,2)} className='event-name'>{data.ename.trim().replace('-', 'v')}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan'>{iplay}</span></td>
{
data.section.length > 0 && data.section.map((data2, index2) => {
return (
<td className='counts-rates' key={index2}>
<span className='back-sports-page'>{data2.odds[0].otype === 'back' ? data2.odds[0].odds : data2.odds[1].odds}</span>
<span className='lay-sports-page'>{data2.odds[1].otype === 'lay' ? data2.odds[1].odds : data2.odds[0].odds}</span>
</td>
)
})   
}
</tr>
</tbody>
)
})  
}
</table>
</div>
</div>
}

{
multiData.length < 1 && multiDataSoc.length < 1 && multiDataTenn.length < 1 &&
<div className='noMultimarket-wrap'>
<div className='noMultimarket'>
<div><img src='/images/blur.png' alt='blur' /></div>
<div>
<span>there are currently no followed multi markets.</span>
<span>please add some markets from event</span>
<div>
<button onClick={multiCric}>cricket</button>
<button onClick={multiSocc}>soccer</button>
<button onClick={multiTenn}>tennis</button>
</div>
</div>
</div> 
</div>  
}

<Footer />
</div>

</div>
</div>
</div>
</div>
</div>
</>
)
}

export default MultiMarket;
