export const playLudoSound = soundname => {
try {
const soundPath = getSound(soundname);
const ludoAudio = new Audio(soundPath);
ludoAudio.play();
} catch (error) {
console.log('can not play ludo audio ' + error);
}
}

const getSound = sound => {
switch (sound) {
case 'dice_roll':
return '/audio/rolling-dice.mp3';

case 'pile_move':
return '/audio/pileMove.mp3';

case 'safe_spots':
return '/audio/safeSpot.mp3';

case 'cut_pile':
return '/audio/cutPile.wav';

case 'win_pile':
return '/audio/winPile.wav';

case 'winner':
return '/audio/fireworks.mp3';

default: return 'no ludo sound available'
}
}