import React, { useCallback, useEffect, useMemo } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Header from '../../extra/Header'
import { backGgroundImage } from './assets/images';
import { GlobalLudo } from './assets/initialState';
import { GlobalContext } from '../../context/AppContext';

const customerDataApi = '/server/active-customer';
const userExposure = '/server/user-exposure';

const LudoStart = () => {

const {ludostate,
updateChancePlayer,
genrateLudoMid, 
updateColorOption,
updateLudoBetAmt, 
updateCurrPlayTeam, 
updatePileOption, 
activeLudoBet
} = GlobalLudo();
const { customer, GetUserList, GetUserExposure } = GlobalContext();

const setSelectTeam = (val) => {
updateCurrPlayTeam(val);
if(val === 4){
updateColorOption(false);   
}
}

const setLudoColOpt = (val) => {
updateColorOption(val);
}

const setLudoPileOpt = (val) => {
updatePileOption(val);
}

const setLudoBetAmt = (val) => {
updateLudoBetAmt(val);
}

const handleLudoStart = useCallback(async() => {
var selBetType = 'back';
var sec = 'match_odds';
var sport = 'ludo';
var eventname = 'virtual casino';
var teamCas;
var bet_rate = '2.00';
var bet_amount = ludostate.activeBetAmount;
var eventid = ludostate.ludoMid;
var betdelay = 1;
let sid = '0';

if(!ludostate.currPlayTeamOpt){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('select team option');
setTimeout(() => {
$('#msgAlert').hide();
}, 1000);
return false;
}
else{
if(ludostate.currPlayTeamOpt === 2){
if(!ludostate.activeColorOption){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('select player color option');
setTimeout(() => {
$('#msgAlert').hide();
}, 1000);
return false;
}
else{
const findUsrOpt = ludostate.userOptions.find((e) => e.option === ludostate.activeColorOption);
//console.log(findUsrOpt);
teamCas = findUsrOpt.player1No === 1 ?
'player 1' : findUsrOpt.player1No === 4 ?
'player 4' : 'player 2';
updateChancePlayer({plNo: findUsrOpt.player1No, playAto: false, noPlayers: -1, autoCell: -1});
}
} 
else{
teamCas = 'player 1';
updateChancePlayer({plNo: 1, playAto: false, noPlayers: -1, autoCell: -1});
}  
}

if(!ludostate.activePileOption){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('select pile option');
setTimeout(() => {
$('#msgAlert').hide();
}, 1000);
return false;  
}
if(ludostate.activeBetAmount <= 0){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('select bet amount');
setTimeout(() => {
$('#msgAlert').hide();
}, 1000);
return false;  
}
else{
try {
const sendData = await axios.post('/server/casino_saveBet', {
selBetType: selBetType,
sec: sec,
sport: sport,
eventname: eventname,
teamCas: teamCas,
bet_rate: bet_rate,
bet_amount: bet_amount,
eventid: eventid,
betdelay: betdelay,
sid: sid
});
const resData = await sendData.data;
if(resData === 'success'){
$('#startLudoLoader').css({display: 'flex'});
setTimeout(() => {
activeLudoBet(true);
reloadBal();
}, 1000);   
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
}, 1000);   
}
} catch (error) {
console.log('frontend ludo start error : ' + error);
}
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[ludostate]);

const reloadBal = () => {
GetUserList(`${customerDataApi}`);
GetUserExposure(`${userExposure}`);
}

useMemo(() => {
if(ludostate.currPlayTeamOpt === 2 && ludostate.activeColorOption && ludostate.activePileOption && ludostate.activeBetAmount){
$('.ludoEnteryStart button').removeClass('active').removeAttr('disabled');
}
else if(ludostate.currPlayTeamOpt === 4 && ludostate.activePileOption && ludostate.activeBetAmount){
$('.ludoEnteryStart button').removeClass('active').removeAttr('disabled');   
}
else{
$('.ludoEnteryStart button').addClass('active').attr('disabled', 'disabled'); 
}
},[ludostate]);

useEffect(() => {
$(document).on('click', '.ludoEnteryColorWrap button', function(){
$(this).addClass('active').siblings().removeClass('active');
});

genrateLudoMid();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

return (
<>
<Header />
<div className='ludoEnteryPage'>
<div className='ludoEnteryBody'>
<div className='startLudoLoader' id='startLudoLoader'>
<div className='loadLudoWrap'>
<div className='loadLudo'>
<span></span>
<span></span>
<span></span>
<span></span>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>ludo loading...</span>
</div>
</div>
<div className='ludoEnteryContent'>
<div className='ludoEnteryColor'>
<div className='ludoEnteryTitle'>select team option</div>
<div className='ludoEnteryColorWrap'>
<button className='' onClick={() => setSelectTeam(2)}>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile4').image} alt='pile-img' />
</div>
</div>
<span>2 X 2</span>
</button>
<button onClick={() => setSelectTeam(4)}>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile4').image} alt='pile-img' />
<img src={backGgroundImage('pile2').image} alt='pile-img' />
<img src={backGgroundImage('pile3').image} alt='pile-img' />
</div>
</div>
<span>4 X 4</span>
</button>
</div>
</div>

{
(ludostate.currPlayTeamOpt === 2) &&
<div className='ludoEnteryColor' style={{marginTop: '10px'}}>
<div className='ludoEnteryTitle'>select Two Player color option</div>
<div className='ludoEnteryColorWrap'>
<button onClick={() => setLudoColOpt('option1')} className=''>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile4').image} alt='pile-img' />
</div>
</div>
<span>red & blue</span>
</button>
<button onClick={() => setLudoColOpt('option2')} >
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile2').image} alt='pile-img' />
</div>
</div>
<span>red & green</span>
</button>
<button onClick={() => setLudoColOpt('option3')}>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile3').image} alt='pile-img' />
</div>
</div>
<span>red & yellow</span>
</button>
<button onClick={() => setLudoColOpt('option4')} >
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile4').image} alt='pile-img' />
<img src={backGgroundImage('pile2').image} alt='pile-img' />
</div>
</div> 
<span>blue & green</span>
</button>
<button onClick={() => setLudoColOpt('option5')} >
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile4').image} alt='pile-img' />
<img src={backGgroundImage('pile3').image} alt='pile-img' />
</div>
</div> 
<span>blue & yellow</span>
</button>
<button onClick={() => setLudoColOpt('option6')} >
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile2').image} alt='pile-img' />
<img src={backGgroundImage('pile3').image} alt='pile-img' />
</div>
</div> 
<span>green & yellow</span>
</button>
</div>
</div>
}


<div className='ludoEnteryColor' style={{marginTop: '10px'}}>
<div className='ludoEnteryTitle'>select pile option</div>
<div className='ludoEnteryColorWrap'>
<button onClick={() => setLudoPileOpt(1)} className=''>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' style={{transform: 'translate(0)'}} />
</div>
</div> 
<span>1 X 1</span>
</button>
<button onClick={() => setLudoPileOpt(2)}>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile2').image} alt='pile-img' />
</div>
</div> 
<span>2 X 2</span>
</button>
<button onClick={() => setLudoPileOpt(3)}>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile2').image} alt='pile-img' />
<img src={backGgroundImage('pile3').image} alt='pile-img' style={{transform: 'translateX(0) translateY(8px)'}} />
</div>
</div> 
<span>3 X 3</span>
</button>
<button onClick={() => setLudoPileOpt(4)}>
<div className='ludoOptImgDiv'>
<div>
<img src={backGgroundImage('pile1').image} alt='pile-img' />
<img src={backGgroundImage('pile2').image} alt='pile-img' />
<img src={backGgroundImage('pile3').image} alt='pile-img' />
<img src={backGgroundImage('pile4').image} alt='pile-img' />
</div>
</div> 
<span>4 X 4</span>
</button>
</div>
</div>

<div className='ludoEnteryColor' style={{marginTop: '10px'}}>
<div className='ludoEnteryTitle'>select bet amount</div>
<div className='ludoEnteryColorWrap'>
<button onClick={() => setLudoBetAmt(customer.length > 0 && customer[0].currency === 'inr' ? 100 : 1)} className=''>
{customer.length > 0 && customer[0].currency === 'inr' ? 100 : 1}
</button>
<button onClick={() => setLudoBetAmt(customer.length > 0 && customer[0].currency === 'inr' ? 500 : 5)}>
{customer.length > 0 && customer[0].currency === 'inr' ? 500 : 5}
</button>
<button onClick={() => setLudoBetAmt(customer.length > 0 && customer[0].currency === 'inr' ? 1000 : 10)}>
{customer.length > 0 && customer[0].currency === 'inr' ? 1000 : 10}
</button>
<button onClick={() => setLudoBetAmt(customer.length > 0 && customer[0].currency === 'inr' ? 2000 : 20)}>
{customer.length > 0 && customer[0].currency === 'inr' ? 2000 : 20}
</button>
<button onClick={() => setLudoBetAmt(customer.length > 0 && customer[0].currency === 'inr' ? 5000 : 50)}>
{customer.length > 0 && customer[0].currency === 'inr' ? 5000 : 50}
</button>
<button onClick={() => setLudoBetAmt(customer.length > 0 && customer[0].currency === 'inr' ? 10000 : 100)}>
{customer.length > 0 && customer[0].currency === 'inr' ? 10000 : 100}
</button>
</div>
</div>

<div className='ludoEnteryStart'>
<button className='active' onClick={handleLudoStart}>start game</button>
</div>

</div>
</div>
</div>
</>
)
}

export default LudoStart;
