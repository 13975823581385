import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const CasinoScroll = () => {

    const { customer, spoSett } = GlobalContext();
    const [lockSport, setLockSport] = useState([]);
    const Navigate = useNavigate();

const t20 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20');   
}
}
else{
Navigate('/all-casino/t20');
}
}
else{
$('#AcceptAgeModal').show();
}
}

const tOne = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20_oneday');   
}
}
else{
Navigate('/all-casino/t20_oneday');
}
}
else{
$('#AcceptAgeModal').show();
}
}

const tTest = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20_test');   
}
}
else{
Navigate('/all-casino/t20_test');
} 
}
else{
$('#AcceptAgeModal').show();
}   
}

const dt20 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt20');   
}
}
else{
Navigate('/all-casino/dt20');
}
}
else{
$('#AcceptAgeModal').show();
}   
}

const dt202 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt202');   
}
}
else{
Navigate('/all-casino/dt202');
}
}
else{
$('#AcceptAgeModal').show();
}   
}

const dtOne = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt_1day');   
}
}
else{
Navigate('/all-casino/dt_1day');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const lu7a = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/lucky_7a');   
}
}
else{
Navigate('/all-casino/lucky_7a');
}
}
else{
$('#AcceptAgeModal').show();
}    
}

const lu7b = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/lucky_7b');   
}
}
else{
Navigate('/all-casino/lucky_7b');
}
}
else{
$('#AcceptAgeModal').show();
}    
}

const andb = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/ab');   
}
}
else{
Navigate('/all-casino/ab');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const headTail = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/head-tails');   
}
}
else{
Navigate('/head-tails');
}
}
else{
$('#AcceptAgeModal').show();
}        
}

const lucky0to9 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/lucky09');   
}
}
else{
Navigate('/lucky09');
}
}
else{
$('#AcceptAgeModal').show();
}       
}

const dice1 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/dice1');   
}
}
else{
Navigate('/dice1');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const ludoking = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/ludoking');   
}
}
else{
Navigate('/ludoking');
}
}
else{
$('#AcceptAgeModal').show();
}  
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

return (
<>
<div className='casinoList_wrap'>
<span className='casinoList_topSpan'>Top Casino</span>
<div className='casinoList_wrap2'> 
<div className='casinoListCont'>
<div className='CasinoListBox' onClick={t20}>
<img src='/images/casinoImg/teenpatti_t20.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={tOne}>
<img src='/images/casinoImg/teenpatti_1day.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={tTest}>
<img src='/images/casinoImg/teenpatti_test.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={dt20}>
<img src='/images/casinoImg/dragon_20.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={dt202}>
<img src='/images/casinoImg/dragon_202.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={dtOne}>
<img src='/images/casinoImg/dragon_1day.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={lu7a}>
<img src='/images/casinoImg/lucky_7a.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={lu7b}>
<img src='/images/casinoImg/lucky_7b.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={andb}>
<img src='/images/casinoImg/andarBahar.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={headTail}>
<img src='/images/casinoImg/headTails.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={lucky0to9}>
<img src='/images/casinoImg/lucky_09.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={dice1}>
<img src='/images/casinoImg/rollDice.png' alt='casinoListImg' />
</div>

<div className='CasinoListBox' onClick={ludoking}>
<img src='/images/casinoImg/ludoKing.png' alt='casinoListImg' />
</div>

</div>
</div>
</div>
</>
)
}

export default CasinoScroll;
