import React, { useCallback, useMemo } from 'react';
import { backGgroundImage } from './assets/images';
import { GlobalLudo } from './assets/initialState';

const Pile = ({color, player, peaceNo, data, onClick, autoPlay}) => {

const { ludostate } = GlobalLudo();
const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
const parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);

const findActOpt = useMemo(() => {
return parseStore.userOptions.find((e) => e.option === parseStore.activeColorOption);
},[parseStore]);

useMemo(() => {
if(parseStore.selectionPilePlayer === player && autoPlay){
const findData = parseStore['player'+player].filter((e) => e.pos > 0);
const findData2 = parseStore['player'+player].filter((e) => e.pos === 0);
//console.log(findData.length +' -- '+ parseStore.activePileOption);
if(findData.length < parseStore.activePileOption){
setTimeout(() => {
onClick(findData2[0]);   
},1000);
}
}
},[parseStore, player, autoPlay, onClick]);

return (
<>
<div className='pileBoxLudo'>
<div className='pilDivLudo'>
<div className='pileLudo' style={{backgroundColor: color}}>
{
data && data[peaceNo].pos === 0 && 
(findActOpt ? (findActOpt.player1No === player ||
findActOpt.player2No === player) : player) && 
peaceNo < parseStore.activePileOption &&
<PilePlot color={color} peaceNo={peaceNo} player={player} onClick={() => onClick(data[peaceNo])} />
}
</div>
</div>
</div>
</>
)
}

const PilePlot = ({color, peaceNo, cell, player, onClick}) => {

const { ludostate } = GlobalLudo();
const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
const parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);

const currPileSelection = parseStore.selectionPilePlayer;
const currCellSelection = parseStore.selectionCellPlayer;
const diceNo = parseStore.diceNo;
const playerPiles = parseStore[`player${player}`];

const isPileEnabled = useMemo(() =>
player === currPileSelection,
[player,currPileSelection]);

const isCellEnabled = useMemo(() =>
player === currCellSelection,
[player,currCellSelection]);

const isForwadble = useCallback(() => {
const peaces = playerPiles.find((e) => e.id === peaceNo);
return peaces && peaces.travel + diceNo <= 57;
},[peaceNo, diceNo, playerPiles]);

return(
<button onClick={onClick} disabled={(!(cell ? isCellEnabled && isForwadble() : isPileEnabled)) || parseStore.autoPlay}>
{
(cell ? isCellEnabled && isForwadble() : isPileEnabled) &&
<div className='activeCircle'></div>
}
<img src={backGgroundImage('pile'+player).image} alt='pile-img' />
</button>
)
}
 
export {Pile, PilePlot};
