import React, { useCallback, useMemo } from 'react';
import axios from 'axios';
import { backGgroundImage } from './assets/images';
import { colors } from './assets/Colors';
import { safeSpot, starSpot, arrowSpot, turningPoint, victoryStart, startPoint } from './assets/plot';
import { GlobalLudo } from './assets/initialState';
import {PilePlot} from './Pile';
import { playLudoSound } from './assets/sound';

const Cell = ({color, cellId}) => {

const { 
disableTouch, 
updatePile, 
unfreezeDice, 
updateChancePlayer, 
aanounceWinner,
ludostate
} = GlobalLudo();

const isSafeSpot = useMemo(() => safeSpot.includes(cellId), [cellId]);
const isStarSpot = useMemo(() => starSpot.includes(cellId), [cellId]);
const isArrowSpot = useMemo(() => arrowSpot.includes(cellId), [cellId]);
const storeLocal = localStorage.getItem('localLudoState');
const storeData = JSON.parse(storeLocal);
var parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);

const pilesAtPos = useMemo(() =>
parseStore.currentPosition.filter((e) => (e.pos) === (cellId)),
[parseStore, cellId]);

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const handleCellPress = useCallback(async(playerNo, peaceNo) => {
//console.log(playerNo +' -- '+ peaceNo +' -- '+ cellId);
var storeLocal = localStorage.getItem('localLudoState');
var storeData = JSON.parse(storeLocal);
var parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
const currentPosition = parseStore.currentPosition;
const diceNo = parseStore.diceNo;
const currUsrOpt = parseStore.userOptions.find((e) => e.option === parseStore.activeColorOption);

const alpha = playerNo === 1 ? 'A' : playerNo === 2 ? 'B' : playerNo === 3 ? 'C' : 'D';

const pileAtPosition = currentPosition.filter((e) => e.pos === cellId);
const cellPiles = pileAtPosition[pileAtPosition.findIndex((o) => o.id[0] === alpha)];

disableTouch();

let finalPath = cellPiles.pos;
const beforePlayerPiles = parseStore[`player${playerNo}`].find((e) => e.id === peaceNo);
var beforeTravel = beforePlayerPiles.travel;

for(let i=0; i < parseInt(diceNo); i++){
storeLocal = localStorage.getItem('localLudoState');
storeData = JSON.parse(storeLocal);
parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
let playerPeaces = parseStore[`player${playerNo}`].find((e) => e.id === peaceNo);
let path = playerPeaces.pos + 1;

//console.log(i)
//console.log(playerPeaces);
//console.log(path);
//console.log(turningPoint);
//console.log(turningPoint[playerNo - 1]);

if(turningPoint.includes(path) && turningPoint[playerNo - 1] === path){
path = victoryStart[playerNo - 1];
}

if(path === 53){
path = 1;
}

finalPath = path;
beforeTravel += 1;   

updatePile({
playerNo : `player${playerNo}`,
pileId : playerPeaces.id,
pos : finalPath,
travel : beforeTravel,
noPlayer : -1
});

//sound play for pile move
parseStore.ludoSound && playLudoSound('pile_move');
await delay(200);
}

storeLocal = localStorage.getItem('localLudoState');
storeData = JSON.parse(storeLocal);
parseStore = storeData !== null && storeData.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
const updatedPosition = parseStore.currentPosition;
const finalPiles = updatedPosition.filter((e) => e.pos === finalPath);
const ids = finalPiles.map((item) => { return item.id[0]});
const uniqueIds = new Set(ids);
const areDiffrentId = uniqueIds.size > 1;

if(safeSpot.includes(finalPath) || starSpot.includes(finalPath)){
// play sound safe spot
parseStore.ludoSound && playLudoSound('safe_spots');
}

if(areDiffrentId &&
!safeSpot.includes(finalPiles[0].pos) && 
!starSpot.includes(finalPiles[0].pos)){

const enemyPile = finalPiles.find((e) => e.id[0] !== peaceNo[0]);
const enemyId = enemyPile.id[0];
let pileNo = enemyId === 'A' ? 1 : enemyId === 'B' ? 2 : enemyId === 'C' ? 3 : 4;

let backPath = startPoint[pileNo - 1];
let i = enemyPile.pos;
//sound play collide
parseStore.ludoSound && playLudoSound('cut_pile');

while(i !== backPath){
updatePile({
playerNo : `player${pileNo}`,
pileId : enemyPile.id,
pos : i,
travel : 0,
noPlayer : -1
});

await delay(0.6);
i--;
if(i === 0){
i = 52;
}
}

updatePile({
playerNo : `player${pileNo}`,
pileId : enemyPile.id,
pos : 0,
travel : 0,
noPlayer : -1
});

let freezeOpen = -1;

if(currUsrOpt){
if(currUsrOpt.player2No === parseStore.chancePlayer){
freezeOpen = currUsrOpt.player2No;
}
else{
freezeOpen = -1;
}
}
else{
if(parseStore.chancePlayer !== 1){
freezeOpen = parseStore.chancePlayer;
}
else{
freezeOpen = -1;
}
}

unfreezeDice({cellFreeze: freezeOpen});
return;
}

//console.log('cell player - '+ playerNo)
//console.log('cell player diceNo - '+ diceNo)
//console.log('cell player finalpath - '+ finalPath)
//console.log('cell player beforeTravel - '+ beforeTravel)

if(diceNo === 6 || beforeTravel === 57){
let chancePlayer = playerNo;
let auto_PlayLudo = false;
let autoCell = -1;

if(currUsrOpt){
if(currUsrOpt.player2No === parseStore.chancePlayer){
chancePlayer = currUsrOpt.player2No;
auto_PlayLudo = true;
autoCell = currUsrOpt.player2No;
}
else{
auto_PlayLudo = false;
autoCell = -1;
}
}
else{
if(chancePlayer !== 1){
auto_PlayLudo  = true;
autoCell = chancePlayer;
}
else{
auto_PlayLudo  = false;
autoCell = -1;
}
}

await delay(600);
updateChancePlayer({plNo: chancePlayer, playAto: auto_PlayLudo, noPlayers: -1, autoCell: autoCell});
if(beforeTravel === 57){
//play sound home win
parseStore.ludoSound && playLudoSound('win_pile');

const playerAllPiles = parseStore[`player${playerNo}`];
if(winningCriteria(playerAllPiles,parseStore.activePileOption)){
aanounceWinner(playerNo);
settleLudo('player '+playerNo,parseStore.activeLudoUser,parseStore.ludoMid);
//play sound winning
parseStore.ludoSound && playLudoSound('winner');
return;
}
// display image winning
unfreezeDice({cellFreeze: autoCell});
return;
}
}
else{

let chancePlayer = playerNo;
let auto_PlayLudo = false;

if(currUsrOpt){
if(currUsrOpt.player1No === parseStore.chancePlayer){
chancePlayer = currUsrOpt.player2No;
auto_PlayLudo = true;
}
else{
chancePlayer = currUsrOpt.player1No;
auto_PlayLudo = false;
}
}
else{
let currDicePlayer = chancePlayer + 1;
chancePlayer = currDicePlayer;
if(chancePlayer > 4){
chancePlayer = 1;
}

if(chancePlayer !== 1){
auto_PlayLudo  = true;
}
else{
auto_PlayLudo  = false;
}
}

await delay(600);
updateChancePlayer({plNo: chancePlayer, playAto: auto_PlayLudo, noPlayers: -1, autoCell: -1});
}

},[ludostate, cellId, disableTouch, updatePile, unfreezeDice, updateChancePlayer, aanounceWinner]);

function winningCriteria(playerAllPiles,activePileOption){
const completePos = playerAllPiles.filter((e) => e.travel >= 57);
//console.log(completePos.length +' -- '+ activePileOption);
if(completePos.length === activePileOption){
return true;
}
else{
return false;
}
}

async function settleLudo(winner,user,mid) {
try {
const sendData = await axios.post('/server/ludoSettle', 
{
user: user,
mid: mid,
winner: winner
}
);
const resData = await sendData.data;
if(resData === 'success'){
//console.log('settle success');
}
else{
console.log('settle error ludo ' + resData);
}
} catch (error) {
console.log('frontend ludo_settle error ' + error);
}
}


useMemo(() => {
if(parseStore.selectionCellPlayer === parseStore.chancePlayer && 
parseStore.selectionPilePlayer === -1 && parseStore.autoPlay){
const playerData = parseStore.chancePlayer === 2 ? 'player2' 
: parseStore.chancePlayer === 3 ? 'player3' : 'player4';
const findPlayerPeace = parseStore[playerData].filter((e) => e.pos !== 0 && (e.travel + parseStore.diceNo) <= 57).splice(0, 1);
//console.log(findPlayerPeace);
if(findPlayerPeace.length > 0 && findPlayerPeace[0].pos === cellId){
setTimeout(() => {
handleCellPress(parseStore.chancePlayer, findPlayerPeace[0].id);
},1000);
}
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[parseStore, cellId]);


return (
<>
<div className='ludoCellDiv' style={{backgroundColor : isSafeSpot ? color : 'white'}}>
{
isStarSpot && 
<img src={backGgroundImage('starIcon').image} alt='star-icon' />
}
{
isArrowSpot && 
<img src={backGgroundImage('turnIcon').image} alt='star-icon' style={
{
transform: cellId === 12 ? 'rotate(-90deg)' : cellId === 25 ? 'rotate(0)' :
cellId === 38 ? 'rotate(90deg)' : 'rotate(180deg)'
}
} />
}
{/*<span>{cellId}</span>*/}
{ 
pilesAtPos.map((data, index) => {

let currPileLength = pilesAtPos.length;

const playerNo = data.id[0] === 'A' ? 1 :
data.id[0] === 'B' ? 2 :
data.id[0] === 'C' ? 3 : 4;

const playerColor = playerNo === 1 ? colors.redColor :
playerNo === 2 ? colors.greenColor :
playerNo === 3 ? colors.yellowColor : colors.blueColor;

return(
<div className='cellPiles' key={data.id} id={'cellPiles'+data.pos}
style={{transform: `scale(${currPileLength === 1 ? 1 : 0.7})
translateX(${currPileLength === 1 ? '0px' : (index % 2) ? '-6px' : '6px'})
translateY(${currPileLength === 1 ? '0px' : (index < 2) ? '-6px' : '6px'})`,
zIndex: parseStore.chancePlayer === playerNo ? 2 : 1
}}>
<PilePlot
cell={true}
player={playerNo}
peaceNo={data.id}
color={playerColor}
onClick={() => handleCellPress(playerNo, data.id)}
/>
</div> 
)
}) 
}
</div>
</>
)
}

export default Cell;
