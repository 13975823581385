import React, {useEffect,useState} from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const CasinoCont = () => {

const { customer, spoSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const Navigate = useNavigate();

const t20 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20');   
}
}
else{
Navigate('/all-casino/t20');
}
}
else{
$('#AcceptAgeModal').show();
}
}

const tOne = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20_oneday');   
}
}
else{
Navigate('/all-casino/t20_oneday');
}
}
else{
$('#AcceptAgeModal').show();
}
}

const tTest = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/t20_test');   
}
}
else{
Navigate('/all-casino/t20_test');
} 
}
else{
$('#AcceptAgeModal').show();
}   
}

const dt20 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt20');   
}
}
else{
Navigate('/all-casino/dt20');
}
}
else{
$('#AcceptAgeModal').show();
}   
}

const dt202 = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt202');   
}
}
else{
Navigate('/all-casino/dt202');
}
}
else{
$('#AcceptAgeModal').show();
}   
}

const dtOne = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/dt_1day');   
}
}
else{
Navigate('/all-casino/dt_1day');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

const lu7a = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/lucky_7a');   
}
}
else{
Navigate('/all-casino/lucky_7a');
}
}
else{
$('#AcceptAgeModal').show();
}    
}

const lu7b = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/lucky_7b');   
}
}
else{
Navigate('/all-casino/lucky_7b');
}
}
else{
$('#AcceptAgeModal').show();
}    
}

const andb = () => {
if (customer.length > 0){
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino/ab');   
}
}
else{
Navigate('/all-casino/ab');
}
}
else{
$('#AcceptAgeModal').show();
}      
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lockCasino').css({display: 'flex'});
}else{
$('#sports_lockCasino').hide();
}
}
},[lockSport]);

return (
<>
<div className='casinoDataAll'>
<div className='sports_lock' id='sports_lockCasino'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<div className='rowsFours'>
<div className='colsFourSm colPointer' onClick={t20}>
<img src='/images/casinoImg/teenpatti_t20Md.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={tOne}>
<img src='/images/casinoImg/teenpatti_1dayMd.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={tTest}>
<img src='/images/casinoImg/teenpatti_testMd.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={dt20}>
<img src='/images/casinoImg/dragon_20Md.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={dt202}>
<img src='/images/casinoImg/dragon_202Md.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={dtOne}>
<img src='/images/casinoImg/dragon_1dayMd.png' alt='casino_img' />
</div>
</div>

<div className='rowsFours'>
<div className='colsFourSm colPointer' onClick={lu7a}>
<img src='/images/casinoImg/lucky_7aMd.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={lu7b}>
<img src='/images/casinoImg/lucky_7bMd.png' alt='casino_img' />
</div>

<div className='colsFourSm colPointer' onClick={andb}>
<img src='/images/casinoImg/andarBaharMd.png' alt='casino_img' />
</div>

</div>
</div>
</>
)
}

export default CasinoCont;
