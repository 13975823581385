import React from 'react';
import { LudoProvider } from './assets/initialState';
import LudoHome from './LudoHome';

const Ludo = () => {
return (
<>
<LudoProvider>
<LudoHome />
</LudoProvider>
</>
)
}

export default Ludo;
