import React, { useEffect } from 'react';
import $ from 'jquery';
import IplRules from '../rules/IplRules';
import WcupRules from '../rules/WcupRules';
import FancyRules from '../rules/FancyRules';
import FancyMarRules from '../rules/FancyMarRules';
import BookmakerRules from '../rules/BookmakerRules';
import SoccerRules from '../rules/SoccerRules';
import TennisRules from '../rules/TennisRules';
import SoccerFancyRules from '../rules/SoccerFancyRules';
import KabaddiRules from '../rules/KabaddiRules';
import ElectionRules from '../rules/ElectionRules';
import KhadoRules from '../rules/KhadoRules';
import Footer from '../extra/Footer';

const GameRules = () => {

const iplRules = () => {
$('.iplRules').fadeIn();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const wcRules = () => {
$('.iplRules').hide();
$('.wcRules').fadeIn();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const fancyRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').fadeIn();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const fancyMarRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').fadeIn();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const bookmakerRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').fadeIn();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const soccerRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').fadeIn();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const tennisRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').fadeIn();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const soccerFancyRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').fadeIn();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const kabaddiRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').fadeIn();
$('.electionRules').hide();
$('.khadoRules').hide();
}

const electionRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').fadeIn();
$('.khadoRules').hide();
}

const khadoRules = () => {
$('.iplRules').hide();
$('.wcRules').hide();
$('.fancyRules').hide();
$('.fancyMarRules').hide();
$('.bookmakerRules').hide();
$('.soccerRules').hide();
$('.tennisRules').hide();
$('.soccerFancyRules').hide();
$('.kabaddiRules').hide();
$('.electionRules').hide();
$('.khadoRules').fadeIn();
}

useEffect(() => {
$('.sidePgeMenu').on('click', function(){
$(this).addClass('activePageSideMenu').siblings().removeClass('activePageSideMenu');
});
},[]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='text-page-head'><img src='/images/book.png' alt='titleImg' /> <h3>game rules</h3></div>
<div className='rowPage'>
<div className='col-two'>
<ul>
<li onClick={() => iplRules()} className='sidePgeMenu activePageSideMenu'>ipl <i className='fa fa-chevron-right'></i></li>
<li onClick={() => wcRules()} className='sidePgeMenu'>world cup <i className='fa fa-chevron-right'></i></li>
<li onClick={() => fancyRules()} className='sidePgeMenu'>fancy <i className='fa fa-chevron-right'></i></li>
<li onClick={() => fancyMarRules()} className='sidePgeMenu'>fancy market 1 <i className='fa fa-chevron-right'></i></li>
<li onClick={() => bookmakerRules()} className='sidePgeMenu'>bookmaker <i className='fa fa-chevron-right'></i></li>
<li onClick={() => soccerRules()} className='sidePgeMenu'>soccer <i className='fa fa-chevron-right'></i></li>
<li onClick={() => tennisRules()} className='sidePgeMenu'>tennis <i className='fa fa-chevron-right'></i></li>
<li onClick={() => soccerFancyRules()} className='sidePgeMenu'>soccer fancy <i className='fa fa-chevron-right'></i></li>
<li onClick={() => kabaddiRules()} className='sidePgeMenu'>kabaddi <i className='fa fa-chevron-right'></i></li>
<li onClick={() => electionRules()} className='sidePgeMenu'>election <i className='fa fa-chevron-right'></i></li>
<li onClick={() => khadoRules()} className='sidePgeMenu'>khado <i className='fa fa-chevron-right'></i></li>
</ul>
</div>
<div className='col-two'>
<IplRules />
<WcupRules />
<FancyRules />
<FancyMarRules />
<BookmakerRules />
<SoccerRules />
<TennisRules />
<SoccerFancyRules />
<KabaddiRules />
<ElectionRules />
<KhadoRules />
</div>
</div>
</div>
<Footer />
</div>
</div>
</div>

</div>
</div> 
</>
)
}

export default GameRules;
