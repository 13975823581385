import React from 'react';
import Footer from '../extra/Footer';

const About = () => {
return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='text-page-head'><img src='/images/about.png' alt='titleImg' /> <h3>about us</h3></div>

<div className='container-med textJustify'>
<ul>
<li> 24 Digital is one of the upcoming providers for online gaming entertainment across Sports Betting, Online and Live Casino operating in the emerging and the regulated markets.</li>
<li>We aim to utilize the latest technologies to provide innovative and interactive gaming experiences in a secure environment.</li>
<li><b>We have dedicated ourselves to offering our customers a seamless and thrilling gaming experience while you are on the go. We aim to provide an exceptional and fully customizable online betting experience.</b></li>
<li><b>We are innovative, ambitious and passionate about what we do. We do it in a credible and responsible way, always aiming for the top.</b></li>
<li>We only operate in regulated markets where we hold the appropriate licenses. We take our responsibilities to customers and our other stakeholders seriously and place great emphasis on working to a ‘compliance first’ model across the business.</li>
<li><b>Dedicated Customer Service Team:</b> We are here for you every step of the way with dedicated customer service managers standing by to provide you with a 24/7 top notch customer care service, handling any issues quickly and efficiently.</li>
<li>When customers bet on our site they can rest assured that they are getting a wide variety of betting options, up to date information and the best odds available.</li>
<li>Our customers also have peace of mind, knowing that when it’s time to collect, they are betting with a well-known reputable company.</li>
<li>We have integrated best and secured payment methods on our site and a transaction process that is quick, easy enabling our players to cash out their winnings quickly and securely.</li>
</ul>

<h4>BUSINESS ADDRESS</h4>
<p>Name- 24 digital.</p>
</div>
</div>
<Footer />
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default About;
