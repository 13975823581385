import { createContext, useContext, useReducer, useMemo } from "react";
import ludoReduce from "./ludoReduce";

const LudoContext = createContext();

const playerOptions = [
{option: 'option1', player1: 'red', player1No: 1, player2: 'blue', player2No: 4},
{option: 'option2', player1: 'red', player1No: 1, player2: 'green', player2No: 2},
{option: 'option3', player1: 'red', player1No: 1, player2: 'yellow', player2No: 3},
{option: 'option4', player1: 'blue', player1No: 4, player2: 'green', player2No: 2},
{option: 'option5', player1: 'blue', player1No: 4, player2: 'yellow', player2No: 3},
{option: 'option6', player1: 'green', player1No: 2, player2: 'yellow', player2No: 3}
];

const player1Initial = [
{id: 'A1', pos: 0, travel: 0},
{id: 'A2', pos: 0, travel: 0},
{id: 'A3', pos: 0, travel: 0},
{id: 'A4', pos: 0, travel: 0}
];

const player2Initial = [
{id: 'B1', pos: 0, travel: 0},
{id: 'B2', pos: 0, travel: 0},
{id: 'B3', pos: 0, travel: 0},
{id: 'B4', pos: 0, travel: 0}
];

const player3Initial = [
{id: 'C1', pos: 0, travel: 0},
{id: 'C2', pos: 0, travel: 0},
{id: 'C3', pos: 0, travel: 0},
{id: 'C4', pos: 0, travel: 0}
];

const player4Initial = [
{id: 'D1', pos: 0, travel: 0},
{id: 'D2', pos: 0, travel: 0},
{id: 'D3', pos: 0, travel: 0},
{id: 'D4', pos: 0, travel: 0}
];

const ludoinitialState = {
player1 : player1Initial,
player2 : player2Initial,
player3 : player3Initial,
player4 : player4Initial,
chancePlayer : 1,
diceNo : 1,
isDiceRoll : false,
selectionPilePlayer : -1,
selectionCellPlayer : -1,
touchDiceBlock : false,
currentPosition : [],
winner : null,
ludoBetStatus : false,
userOptions: playerOptions,
currPlayTeamOpt : false,
activeColorOption : false,
activePileOption : false,
activeBetAmount : 0,
ludoMid : 0,
ludoSound: false,
activeLudoUser: false,
autoPlayPile: -1,
autoPlayCell: -1,
autoPlay: false
}

const ludoinitialState2 = {
player1 : player1Initial,
player2 : player2Initial,
player3 : player3Initial,
player4 : player4Initial,
chancePlayer : 1,
diceNo : 1,
isDiceRoll : false,
selectionPilePlayer : -1,
selectionCellPlayer : -1,
touchDiceBlock : false,
currentPosition : [],
winner : null,
ludoBetStatus : false,
userOptions: playerOptions,
currPlayTeamOpt : false,
activeColorOption : false,
activePileOption : false,
activeBetAmount : 0,
ludoMid : 0,
ludoSound: false,
activeLudoUser: false,
autoPlayPile: -1,
autoPlayCell: -1,
autoPlay: false
}

const LudoProvider = ({children}) => {
const [ludostate, dispatch] = useReducer(ludoReduce, ludoinitialState);

const updateDice = (val) => {
dispatch({type : 'dice_run', payload: val});
}

const enablePilePlayer = (val) => {
dispatch({type : 'enable_pile_player', payload: val}); 
}

const enableCellPlayer = (val) => {
dispatch({type : 'enable_cell_player', payload: val}); 
}

const disableTouch = (val) => {
dispatch({type : 'disable_touch', payload: val}); 
}

const unfreezeDice = (val) => {
dispatch({type : 'unfreeze_dice', payload: val}); 
}

const updateWinner = (val) => {
dispatch({type : 'winner_ludo', payload: val}); 
}

const updateChancePlayer = (val) => {
dispatch({type : 'chance_player', payload: val}); 
}

const updatePile = (val) => {
dispatch({type : 'update_pile', payload: val}); 
}

const aanounceWinner = (val) => {
dispatch({type : 'aanounce_winner', payload: val}); 
}

const updateCurrPlayTeam = (val) => {
dispatch({type : 'update_currPlay_teamOpt', payload: val}); 
}

const updateColorOption = (val) => {
dispatch({type : 'update_color_option', payload: val}); 
}

const updatePileOption = (val) => {
dispatch({type : 'update_pile_option', payload: val}); 
}

const updateLudoBetAmt = (val) => {
dispatch({type : 'update_LudoBet_amount', payload: val}); 
}

const genrateLudoMid = () => {
dispatch({type: 'genrate_ludoMid'});
}

const activeLudoBet = (val) => {
dispatch({type: 'active_ludo_bet', payload: val});
}

const resetLudoState = (val) => {
const store = JSON.parse(localStorage.getItem('localLudoState'));
const matchStore = store !== null && store.filter((e) => e.activeLudoUser !== ludostate.activeLudoUser && e.activeLudoUser);
localStorage.setItem('localLudoState', JSON.stringify(matchStore));
dispatch({type: 'reset_ludo_state', payload: ludoinitialState2});
}

const ludoSoundUpdate = (val) => {
dispatch({type: 'ludo_sound_update', payload: val});
}

const ludoUsrUpdate = (val) => {
dispatch({type: 'ludo_user_update', payload: val});
}

const autoPlayLudo = (val) => {
dispatch({type: 'auto_play_ludo', payload: val});
}

const autoCell = (val) => {
dispatch({type: 'update_autoCell', payload: val});
}

useMemo(() => {
//localStorage.clear();
if(ludostate.ludoBetStatus){
const store = JSON.parse(localStorage.getItem('localLudoState')) || [];
const findData = store.find((e) => e.activeLudoUser === ludostate.activeLudoUser);
if(findData){
Object.assign(findData, ludostate);
}
else{
store.push(ludostate)   
}
localStorage.setItem('localLudoState', JSON.stringify(store));
}

const store = JSON.parse(localStorage.getItem('localLudoState'));
const storeFind = store !== null && store.find((e) => e.activeLudoUser === ludostate.activeLudoUser); 
//console.log(storeFind)

if(storeFind){
ludostate.player1 = storeFind.player1;
ludostate.player2 = storeFind.player2;
ludostate.player3 = storeFind.player3;
ludostate.player4 = storeFind.player4;
ludostate.chancePlayer = storeFind.chancePlayer;
ludostate.diceNo = storeFind.diceNo;
ludostate.isDiceRoll = storeFind.isDiceRoll;
ludostate.selectionPilePlayer = storeFind.selectionPilePlayer;
ludostate.selectionCellPlayer = storeFind.selectionCellPlayer;
ludostate.touchDiceBlock = storeFind.touchDiceBlock;
ludostate.currentPosition = storeFind.currentPosition;
ludostate.winner = storeFind.winner;
ludostate.ludoBetStatus = storeFind.ludoBetStatus;
ludostate.userOptions = storeFind.userOptions;
ludostate.currPlayTeamOpt = storeFind.currPlayTeamOpt;
ludostate.activeColorOption = storeFind.activeColorOption;
ludostate.activePileOption = storeFind.activePileOption;
ludostate.activeBetAmount = storeFind.activeBetAmount;
ludostate.ludoMid = storeFind.ludoMid;
ludostate.ludoSound = storeFind.ludoSound;
ludostate.activeLudoUser = storeFind.activeLudoUser;
ludostate.autoPlay = storeFind.autoPlay;
ludostate.autoPlayPile = storeFind.autoPlayPile;
ludostate.autoPlayCell = storeFind.autoPlayCell;
}
},[ludostate]);

return (
<LudoContext.Provider 
value={{
ludostate,
autoPlayLudo,
autoCell,
ludoUsrUpdate, 
ludoSoundUpdate, 
resetLudoState, 
activeLudoBet, 
genrateLudoMid,
updatePileOption,
updateCurrPlayTeam,
updateLudoBetAmt,
updateColorOption,
aanounceWinner,
updateDice,
updateChancePlayer, 
enablePilePlayer,
enableCellPlayer,
disableTouch,
unfreezeDice,
updateWinner, 
updatePile
}}>
{children}
</LudoContext.Provider>
)
}

const GlobalLudo = () => {
return useContext(LudoContext);
}

export {LudoProvider, GlobalLudo};