import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/AppContext';
import { 
stakeBet,
minusAmt,
plusAmt,
changeAmt,
oddsClick,
allMid,
allTeamLine,
cancelBet,
betSend,
lineFancy_pls
} from '../js/lineBetting';
import numFormat from '../../NumFormat';
import { useParams } from 'react-router-dom';

const Line = () => {

const { customer, cricMarket } = GlobalContext();
const [currData, setCurrData] = useState([]);
const { eventid, marketid } = useParams();

//console.log(currData);

useEffect(() => {
if(cricMarket.length !== 0){
setCurrData(cricMarket.data !== undefined && cricMarket.data.filter((e) => e.gtype === "fancy2"));
}
},[cricMarket]);

useEffect(() => {
if(currData.length > 0){
lineFancy_pls(eventid,'cricket');
}
},[currData, eventid]);

return (
<>
{
currData.length > 0 && 
<table className='oddsTable fancyTab'>
<thead>
<tr>
<th className='minWidth' colSpan='2'>
<span className='min-max diskHide'><span className='minF'>min/max :</span> <span className='minS matchMinFancy'>0-1</span></span>
</th>
<th className='noth' style={{width: '11%'}}>no</th>
<th className='yesth' style={{width: '11%'}}>yes</th>
<th className='minMaxTh mobHide'>Min-Max</th>
</tr>
</thead>
{
currData.map((main,mainIndex) => {
if(!allMid.includes(main.mid)){
allMid.push(main.mid);
}

var RunData = {
sec : 'line',
runner: main.mname,
mid: main.mid,
}

const findSid = allTeamLine.findIndex(o => o.mid === main.mid);

if(findSid === -1){
allTeamLine.push(RunData);
}

return (
<tbody key={mainIndex} style={{position: 'relative'}}>

{
currData.length > 0 &&
<tr className='blockTrF' id={'blockTrF_'+main.mid}>
<td colSpan='5'><span id={'blockTrF_txt_'+main.mid}>suspended</span></td>   
</tr>
}

<tr>
<td colSpan='5' className='teamLine' id={'teamSel_'+main.mid}>{main.mname}</td>
</tr>

{
main.section.map((data, index) => {
return (
<tr key={index}>
<td style={{verticalAlign: 'top'}}>
<span className='teamName' style={{marginTop:'5px'}}>{data.nat}</span>
<span className='pls' id={'plsArr_'+main.mid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+main.mid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+main.mid}></span>
</td>

<td></td>

<td colSpan='2' style={{position: 'relative', padding: '0'}}>
<div className='odds-two'>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay1').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='lay1Div oddsDiv' id={'oddsDivLay_'+main.mid+data.sid+data2.tno} onClick={() => oddsClick(main.mid,data.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+main.mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+main.mid+data.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back1').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='back1Div oddsDiv' id={'oddsDiv_'+main.mid+data.sid+data2.tno} onClick={() => oddsClick(main.mid,data.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+main.mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+main.mid+data.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}
</div>

<div className='odds-two'>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay2').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='lay1Div oddsDiv' id={'oddsDivLay_'+main.mid+data.sid+data2.tno} onClick={() => oddsClick(main.mid,data.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+main.mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+main.mid+data.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back2').map((data2, index2) => {
return(
<div className='counts-tdFull' key={index2}>
<button className='back1Div oddsDiv' id={'oddsDiv_'+main.mid+data.sid+data2.tno} onClick={() => oddsClick(main.mid,data.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+main.mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+main.mid+data.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}
</div>

<div className='odds-two'>
{
data.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay3').map((data2, index2) => {
return(
<div className='counts-tdFull lastCount' key={index2}>
<button className='lay1Div oddsDiv' id={'oddsDivLay_'+main.mid+data.sid+data2.tno} onClick={() => oddsClick(main.mid,data.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+main.mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_laysize_'+main.mid+data.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}

{
data.odds.filter((e) => e.otype === 'back' && e.oname === 'back3').map((data2, index2) => {
return(
<div className='counts-tdFull lastCount' key={index2}>
<button className='back1Div oddsDiv' id={'oddsDiv_'+main.mid+data.sid+data2.tno} onClick={() => oddsClick(main.mid,data.sid,data2.otype,data2.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+main.mid+data.sid+data2.tno}>{data2.odds}</span>
<span className='size' id={'price_size_'+main.mid+data.sid+data2.tno}>{numFormat(data2.size)}</span>
</button>
</div>
)
})   
}
</div>

<div className='suspendSmall2' id={'suspendFancy_'+main.mid+data.sid}>
<span id={'suspendFancyTxt_'+main.mid+data.sid}>suspended</span>
</div>
</td>

<td style={{verticalAlign: 'top'}} className='minMaxTd mobHide'>
<span className='minMax matchMinFancy'>0-1</span>
</td>

</tr>
)
})
}

<tr style={{display:'none'}} id={'placeBet_'+main.mid}>
<td style={{padding:'0'}} colSpan='7'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrap_'+main.mid}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msg_'+main.mid}></span>
</div>
<div className='bet-input-row' id={'betRow_'+main.mid}>
<span style={{display:'none'}} id={'selBetType_'+main.mid}></span>
<span style={{display:'none'}} id={'currSid_'+main.mid}></span>
<span style={{display:'none'}} id={'tno_'+main.mid}></span>
<span style={{display:'none'}} id={'sec_'+main.mid}>line</span>
<span style={{display:'none'}} id={'sport_'+main.mid}>cricket</span>
<span style={{display:'none'}} id={'eventid_'+main.mid}>{eventid}</span>
<span style={{display:'none'}} id={'marketid_'+main.mid}>{marketid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet(main.mid)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id={'bet-rate_'+main.mid} placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt(main.mid)}>-</span>
<input type='number' id={'bet-amount_'+main.mid} placeholder='0' onChange={(e) => changeAmt(main.mid,e.target.value)} />
<span onClick={() => plusAmt(main.mid)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend(main.mid)} className='' id={'betsPlace_'+main.mid}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_'+main.mid}>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet(main.mid, customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet(main.mid)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend(main.mid)} className='' id={'betsPlace2_'+main.mid}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>
</tbody>
)
})
}
</table>
}
</>
)
}

export default Line;
