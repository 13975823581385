import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';
import MarketBets from '../../allBets/MarketBets';
import { check_t20, start_t20 } from './js/refresh';
import { startResult_t20, runresult_t20 }  from './js/result';
import { runSettle_t20, startSettle_t20 } from './js/settle';
import ResultMod from '../modals/ResultMod';
import T20Rules from '../modals/T20Rules';
import {
oddsClick_t20,
cancelBet_t20,
minusAmt_t20,
plusAmt_t20,
changeAmt_t20,
stakeBet_t20,
betSend_t20,
pls_t20
} from './js/betting';

const T20 = () => {

const [load_t20, setLoadT20] = useState(true);
const [curr_t20, setCurrT20] = useState([]);
const { customer } = GlobalContext();

const openT20Rules = () => {
$('#t20Modal').show();
}

useEffect(() => {
async function fetcht20() {
try {
const getData = await axios.get('/server/teenpattiT20Data');
const resData = await getData.data;
if(resData.data !== undefined){
setLoadT20(false);
setCurrT20(resData);
}
else{
setLoadT20(true);
setCurrT20([]);
}
} catch (error) {
console.log('frontend casino_t20_data error : ' + error);
}
}

fetcht20();
},[]);

useEffect(() => {
check_t20();
runresult_t20();
startSettle_t20();
pls_t20(curr_t20.data !== undefined ? curr_t20.data.t1[0].mid : 0,'t20');
return () => {
clearInterval(start_t20);
clearInterval(startResult_t20);
clearInterval(runSettle_t20);
}
},[curr_t20]);

if(load_t20){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-lg'>
<div className='game-body'>
<div className='game-contents'>

{
curr_t20.data !== undefined &&
<div className='casinoGame_wrap'>
<div className='headerCasGame'>
<div className='col'>
<span className='casGameTitle'>teenpatti 20 20</span>
<span className='casGamerule' onClick={openT20Rules}>rules</span>
</div>
<div className='col'>
<span className='casRoundId'>mid : <span id='roundId_t20'>{curr_t20.data.t1[0].mid}</span></span>
</div>
</div>

<div className='videoWrapCas'>
<div className='liveCards'>
<div className='col'>
<span className='cardTeam'>player a</span>
<div className='teamLiveCards'>
<img id='card1_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c1.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card2_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c2.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card3_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c3.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div> 
<div className='col'>
<span className='cardTeam'>player b</span>
<div className='teamLiveCards'>
<img id='card4_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c4.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card5_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c5.toUpperCase())+'.jpg'} alt='casino cards' />
<img id='card6_t20' src={'https://versionobj.ecoassetsservice.com/v18/static/front/img/cards/'+(curr_t20.data.t1[0].c6.toUpperCase())+'.jpg'} alt='casino cards' />
</div>
</div>    
</div>   
<div className='countDownWrap'>
<div className='countDown'></div> 
<div className='countTxt'><span id='autoTime_t20'>{curr_t20.data.t1[0].autotime}</span></div>   
</div> 
<div className='frameWrap'>
<iframe src='https://alpha-n.qnsports.live/route/ash.php?id=3030' title='casino video'></iframe>
</div>
</div>

<div className='casDataWrap'>
<table className='casTable'>
<thead>
<tr>
<th></th>
<th className='backTh'><span>back</span></th>
<th className='backTh'></th>
</tr>
</thead>
<tbody>
<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t20.data.t2[0].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t20_'+curr_t20.data.t2[0].sid}>0.00</span>
<span className='plsCas' id={'plsCasBef_t20_'+curr_t20.data.t2[0].sid}></span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t20_'+curr_t20.data.t2[0].sid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[0].sid} onClick={() => oddsClick_t20(curr_t20.data.t2[0].sid)}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[0].sid}>{curr_t20.data.t2[0].rate}</span>
<span className='casSize'>0.00</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[1].sid} onClick={() => oddsClick_t20(curr_t20.data.t2[1].sid)}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[1].sid}>{curr_t20.data.t2[1].rate}</span>
<span className='casSize'>{curr_t20.data.t2[1].nation}</span>
</button>
</td>
</tr>

<tr>
<td>
<div className='teamTdCas'>
<span>{curr_t20.data.t2[2].nation}</span>
<div className='plsDiv'>
<span className='plsCas' id={'plsCas_t20_'+curr_t20.data.t2[2].sid}>0.00</span>
<span className='plsCas' id={'plsCasBef_t20_'+curr_t20.data.t2[2].sid}></span>
</div>
</div>
</td>
<td className='countOdds backCasBox'>
<div className='locCasino' id={'locCasino_t20_'+curr_t20.data.t2[2].sid}><i className='fa fa-lock'></i></div>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[2].sid} onClick={() => oddsClick_t20(curr_t20.data.t2[2].sid)}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[2].sid}>{curr_t20.data.t2[2].rate}</span>
<span className='casSize'>0.00</span>
</button>
</td>
<td className='countOdds backCasBox'>
<button className='odds_cas' id={'oddsCas_t20_'+curr_t20.data.t2[3].sid} onClick={() => oddsClick_t20(curr_t20.data.t2[3].sid)}>
<span className='casRate' id={'casRate_t20_'+curr_t20.data.t2[3].sid}>{curr_t20.data.t2[3].rate}</span>
<span className='casSize'>{curr_t20.data.t2[3].nation}</span>
</button>
</td>
</tr>

<tr style={{display:'none'}} id='placeBetCasino_t20'>
<td style={{padding:'0'}} colSpan='3'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id='betMsgWrapCas_t20'>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id='bet_msgCas_t20'></span>
</div>
<div className='bet-input-row'>
<span style={{display:'none'}} id='selBetType_t20'>back</span>
<span style={{display:'none'}} id='sec_t20'>match_odds</span>
<span style={{display:'none'}} id='sport_t20'>t20</span>
<span style={{display:'none'}} id='teamCas_t20'></span>
<span style={{display:'none'}} id='teamSid_t20'></span>
<span style={{display:'none'}} id='userCurr_t20'>{customer.length > 0 ? customer[0].currency : 'inr'}</span>
<span style={{display:'none'}} id='eventid_t20'>{curr_t20.data.t1[0].mid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet_t20()}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id='bet_rate_t20' placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt_t20()}>-</span>
<input type='number' id='bet_amount_t20' placeholder='0' onChange={(e) => changeAmt_t20(e.target.value)} />
<span onClick={() => plusAmt_t20()}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend_t20()} className='' id='betsPlace_t20'>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id='betColBet_t20'>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet_t20(customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet_t20()}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend_t20()} className='' id='betsPlace2_t20'>place bet</button>
</div> 
</div>
</div>
</td>
</tr>

</tbody>
</table>
</div>

<div className='casinoResWrap'>
<div className='casinoResTitle'><span>last result</span></div>
<div className='allResCas' id='allResCas_t20'></div>
</div>

<MarketBets eventid={curr_t20.data !== undefined ? curr_t20.data.t1[0].mid : 0} />
</div>
}

</div>
</div>
</div>
</div>
</div>
</div>

<T20Rules />
<ResultMod />
</>
)
}

export default T20;
